import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignupComponent } from "./components/home/signup/signup/signup.component";
import { RedirectGuard } from "./utils/RedirectGuard";
import { SimTrackingModule } from "./components/sim-tracking/sim-tracking.module";
/*import { KnowledgeBaseComponent } from './components/knowledge-base/knowledge-base.component';*/

const routes: Routes = [
  { path: "", redirectTo: "ingreso", pathMatch: "full" },

  {
    path: "promo/:txid",
    loadChildren: () =>
      import("./components/promo/promo.module").then((m) => m.PromoModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./components/inicio/inicio.module").then((m) => m.InicioModule),
    data: {
      breadcrumb: "",
      title: "Compra - Virgin Mobile Colombia",
    },
  },
  {
    path: "compra",
    loadChildren: () =>
      import("./components/compra/compra.module").then((m) => m.CompraModule),
    data: {
      breadcrumb: "Compra",
      title: "Compra - Virgin Mobile Colombia",
    },
  },
  {
    path: "antiplanes",
    loadChildren: () =>
      import("./components/product-catalog/product-catalog.module").then(
        (m) => m.ProductCatalogModule
      ),
    data: {
      breadcrumb: "Planes",
      title: "Compra - Virgin Mobile Colombia",
      description:
        "¡Los Antiplanes son como un plan de los otros, pero bien! Están compuestos de todos los recursos: Minutos, Megas,recursos ilimitados y mensajes de textos. Puedes activarlos cuando quieras o de acuerdo con tu bolsillo. En Virgin Mobile eres libre y no te atamos a nada porque somos una telefonía 100% prepago.!",
      keywords:
        "Antiplanes, recursos, Virgin Mobile, Prepago, Telefonía móvil, internet movil ilimitado, $75.000, Internet ilimitado Virgin, Ilimitado, ilimitado, ilimitado",
    },
  },
  {
    path: "bolsas",
    loadChildren: () =>
      import("./components/product-catalog/product-catalog.module").then(
        (m) => m.ProductCatalogModule
      ),
    data: {
      breadcrumb: "Bolsas",
      title: "Compra - Virgin Mobile Colombia",
      description:
        "Las Bolsas de Voz, datos y chat de Whatsapp  son productos especialmente para hacer llamadas, navegar o chatear. Hay de todos los colores y sabores desde $1.000 pesitos hasta $40.000.",
      keywords: "bolsas, paquetes, Whatsapp, chat, datos, voz",
    },
  },
  {
    path: "sim-linea-nueva",
    loadChildren:
      "./components/sims-catalog/sims-catalog.module#SimsCatalogModule",
    data: {
      breadcrumb: "sims > Sim Línea nueva",
      title: "Compra - Virgin Mobile Colombia",
      description:
        "Puedes pedir tu SIM Virgin Mobile de forma fácil, en nuestra página web.  Si eres un cliente nuevo, te damos la opción de comprar directamente un producto que viene con la Sim Card y el envío GRATIS, sólo pagas el valor del Plan.",
      keywords:
        "compra, sims, sim card, Virgin Mobile, Prepago, Telefonía móvil, internet movil ilimitado, $75.000, Internet ilimitado Virgin, Ilimitado, ilimitado, ilimitado",
    },
  },
  {
    path: "sim-de-reposicion",
    loadChildren:
      "./components/sims-catalog/sims-catalog.module#SimsCatalogModule",
    data: {
      breadcrumb: "sims > sim de reposición",
      title: "Compra - Virgin Mobile Colombia",
      description:
        "Puedes pedir tu SIM Virgin Mobile de forma fácil, en nuestra página web.  Si eres un cliente nuevo, te damos la opción de comprar directamente un producto que viene con la Sim Card y el envío GRATIS, sólo pagas el valor del Plan.",
      keywords:
        "compra, sims, sim card, Virgin Mobile, Prepago, Telefonía móvil, internet movil ilimitado, $75.000, Internet ilimitado Virgin, Ilimitado, ilimitado, ilimitado",
    },
  },
  /* {
    path: 'compra-tu-sim',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.virginmobile.co/sims/'
    }
  }, */
  {
    path: "recargas",
    loadChildren: () =>
      import("./components/recargas/recargas.module").then(
        (m) => m.RecargasModule
      ),
    data: {
      breadcrumb: "Recargas",
      title: "Compra - Virgin Mobile Colombia",
      description:
        "Es nuestro producto básico. Puedes recargar cualquier valor a partir de $1.000 y hasta $100.000 por nuestra web/app. Si no tienes tarjeta , tranqui puedes pedir por nuestra web/app e ir un punto Efecty y pagar.",
      keywords: "Planes, Efecty, Virgin Mobile, Prepago, Telefonía móvil",
    },
  },
  {
    path: "carrito-de-compra",
    loadChildren: () =>
      import("./components/checkout-guest/checkout-guest.module").then(
        (m) => m.CheckoutGuestModule
      ),
    data: {
      breadcrumb: "CARRITO DE COMPRA",
      title: "Compra - Virgin Mobile Colombia",
    },
  },
  { path: "signup", component: SignupComponent },
  {
    path: "mi-cuenta",
    loadChildren: () =>
      import("./components/selfcare/selfcare.module").then(
        (m) => m.SelfCareModule
      ),
    data: {
      breadcrumb: "MI CUENTA",
      title: "Mi Cuenta - Virgin Mobile Colombia",
    },
  },
  /* {
      path: 'sim-repo',
      loadChildren: () => import('./components/sim-repo/sim-repo.module').then(m => m.SimRepoModule),
      data: {
          breadcrumb: 'REPOSICIÓN DE SIM',
          title: 'Mi Cuenta - Virgin Mobile Colombia',
      }
  }, */
  {
    path: "ayuda",
    loadChildren: () =>
      import("./components/knowledge-base/knowledge-base.module").then(
        (m) => m.KnowledgeBaseModule
      ),
    data: {
      breadcrumb: "INICIO > AYUDA",
      title: "Ayuda - Virgin Mobile Colombia",
    },
  },
  {
    path: "pasate",
    loadChildren: () =>
      import("./components/leads/leads.module").then((m) => m.LeadsModule),
    data: {
      breadcrumb: "PÁSATE",
      title: "Virgin Mobile Colombia",
    },
  },
  {
    path: "seguimiento-de-sim-card/:regid",
    loadChildren: () =>
      import("./components/sim-tracking/sim-tracking.module").then(
        (m) => m.SimTrackingModule
      ),
    data: {
      breadcrumb: "SEGUIMIENTO DE SIM CARD",
      title: "Virgin Mobile Colombia",
    },
  },
  {
    path: "personaliza",
    loadChildren: () =>
      import("./components/customize/customize.module").then(
        (m) => m.CustomizeModule
      ),
    data: {
      breadcrumb: "Personaliza tu Plan",
      title: "Virgin Mobile Colombia",
    },
  },
  {
    path: "promociones-de-fidelizacion",
    loadChildren: () =>
      import("./components/loyalty-microsite/loyalty-microsite.module").then(
        (m) => m.LoyaltyMicrositeModule
      ),
    data: {
      breadcrumb: "Información importante para usuarios  >  Promociones de fidelización y retención",
      title: "Virgin Mobile Colombia",
    },
  },
  { path: "**", redirectTo: "/ingreso", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
