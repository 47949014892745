export const environment = {
  production: true,
  versionCheckURL: '/inicio/assets/config/version.json',
  knowledgeBase_user: 'adminfinder',
  knowledgeBase_pw: 'finder2018',
  timerCheckVersion: 5, // cada 30 minutos
  FacebookId: '200854648325258',
  VersionFacebook: 'v4.0',
  GoogleId: '797801414770-12tqbuvu5qidb706556ko7rgfqfte4gt.apps.googleusercontent.com',
  UrlOrigen: window.location.protocol + '//' + window.location.hostname + '/inicio', // 'https://www.virginmobile.co/inicio', // prueba locales
  // UrlOrigen: 'https://www.virginmobile.co/inicio', // mientras se este realizando pruebas en QA,
  // UrlOrigen: 'http://vmco-dev-leads3r.s3-website-us-east-1.amazonaws.com',
  // UrlOrigen: 'https://www.virginmobile.co/ingreso', // cuando ya se pase a produccion
  ApiGoogleMaps: 'AIzaSyDbmObgYf305xssDoV0v9Q7pQ6ddbh2PLQ',
  countRequiredProductAditional: 1,
  accessKey: '977a9e14-0a83-40e9-941c-58d5087e84d2'
};
