import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { ThemeLoaderComponent } from 'src/app/components/commons/theme-loader/theme-loader.component';
import { NoAssociatedLinesComponent } from 'src/app/components/commons/no-associated-lines/no-associated-lines.component';
import { MessageModule } from 'primeng/message';
import { CheckboxModule } from 'primeng/checkbox';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PurchaseSummaryComponent } from 'src/app/components/checkout-guest/purchase-summary/purchase-summary.component';
import { LoginRightContentComponent } from 'src/app/components/login/login-right-content/login-right-content.component';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ButtonModule,
    CardModule,
    TableModule,
    InputTextModule,
    InputMaskModule,
    ReactiveFormsModule,
    MessageModule,
    CheckboxModule,
    KeyFilterModule,
    DialogModule,
    AccordionModule,
    TabViewModule,
    CalendarModule,
  ],
  declarations: [ThemeLoaderComponent, NoAssociatedLinesComponent, PurchaseSummaryComponent, LoginRightContentComponent],
  exports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CardModule,
    TableModule,
    InputTextModule,
    InputMaskModule,
    ThemeLoaderComponent,
    NoAssociatedLinesComponent,
    PurchaseSummaryComponent,
    LoginRightContentComponent,
    MessageModule,
    CheckboxModule,
    KeyFilterModule,
    DialogModule,
    AccordionModule,
    TabViewModule,
    CalendarModule
  ]
})
export class SharedModule { }
