import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginUserDto } from '../../model/security-access/login-user-dto';
import { GeneralResponse } from '../../model/commons/response/general-response';
import { Observable, throwError } from 'rxjs';
import { SecurityAccessConstants } from '../../utils/constants/endpoints/security-access/security-access-constants';
import { UserDto } from 'src/app/model/security-access/user-dto';
import { SocialNetworkTokenVericationDto } from 'src/app/model/security-access/social-network-token-verication-dto';
import { UserResetDto } from 'src/app/model/security-access/user-reset-dto';
import { DataService } from '../product-catalog/data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, public jwtHelper: JwtHelperService, private dataService: DataService) { }

  public loginUser(loginUserDto: LoginUserDto): Observable<GeneralResponse> {
    this.logout();
    return this.http.post<GeneralResponse>(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/login-user', loginUserDto);
    /* .pipe(
      tap(
        (response) => {
          console.log(response);
        }
      ),
      catchError(this.handleError)
    ); */
  }

  public loginSocialNetwork(socialNetworkTokenVericationDto: SocialNetworkTokenVericationDto): Observable<GeneralResponse> {
    this.logout();
    return this.http.post<GeneralResponse>(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/login-social-network', socialNetworkTokenVericationDto);
    /* .pipe(
      tap(
        (response) => {
          console.log(response);
        }
      ),
      catchError(this.handleError)
    ); */
  }

  public forgotPassword(userResetDto: UserResetDto): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/forgot-password', userResetDto);
  }

  public validateCode(userDto: UserDto): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/validate-code', userDto);
  }

  public updatePassword(userDto: UserDto): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/update-password', userDto);
  }

  public enableAccount(userResetDto: UserResetDto): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/enable-account', userResetDto);
  }

  public resendRegisterCode(userResetDto: UserResetDto): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/resend/register', userResetDto);
  }



  public saveToken(token: string, nickname?: string, imageUrl?: string): void {
    localStorage.setItem('access_token', token);
    console.log(' Agrega el token a storage');
    const decodedToken = this.jwtHelper.decodeToken(token);
    if (decodedToken) {
      if (decodedToken.names != null) {
        localStorage.setItem('names', decodedToken.names);
        localStorage.setItem('nickname', decodedToken.names);
      }
      if (decodedToken.lastnames != null) {
        localStorage.setItem('lastnames', decodedToken.lastnames);
        localStorage.setItem('nickname', decodedToken.names + ' ' + decodedToken.lastnames);
      }
      localStorage.setItem('username', decodedToken.sub);

      if (imageUrl != null) {
        localStorage.setItem('imageUrl', imageUrl);
      }
      if (nickname) {
        localStorage.setItem('nickname', nickname);
      }

      console.log(' Agrega data adicional a storage');
    }
  }

  public signup(userDto: UserDto): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/signup', userDto);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    if (token == null) {
      return false;
    } else {
      if (this.jwtHelper.isTokenExpired(token)) {
        this.logout();
        return false;
      }
      return true;
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('An error occurred:', error);
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
      // console.dir(error);
    }
    return throwError(error);
  }

  /**
   * Function to logout a user from the system
   */

  public logout(): void {
    console.log(' on logout Service');
    // --- Variables que pueden seguir al hacer un logout
    const selectedCatatalog = this.dataService.getSelectedCatalogItem();
    const selectedSimCatatalog = this.dataService.getSelectedSimCatalogItem();
    // --- Lista de adicionales
    const aditionalsList = this.dataService.getAddOnList();
    // --- descuento seleccionado
    const selectedDiscount = this.dataService.getSelectedDiscount();
    // --- codigo promocional
    const promotionalCode = this.dataService.getPromotionalCode();

    localStorage.clear();
    sessionStorage.clear();

    this.dataService.updateSelectedCatalogItem(selectedCatatalog);
    this.dataService.updateSelectedSimCatalogItem(selectedSimCatatalog);
    this.dataService.updateAddOnList(aditionalsList);
    this.dataService.updateSelectedDiscount(selectedDiscount);
    this.dataService.updatePromotionalCode(promotionalCode);
  }

  public getApiFacebook(token: string): Observable<any> {
    return this.http.get<any>(SecurityAccessConstants.URL_API_FACEBOOK + token);
  }


  public getSecurityAccesTokenINew(): Observable<any>{
    return this.http.get<any>(SecurityAccessConstants.URLSECURITYACCESS + '/users/key-services');
  }
}
