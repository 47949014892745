import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  private messageSource = new BehaviorSubject(null);
  currentShoppingCart = this.messageSource.asObservable();

  constructor() { }

  /**
   * Actualiza el valor del carrito de compras
   * @param currentShoppingCart
   */
  changeShoppingCart(currentShoppingCart: any) {
    this.messageSource.next(currentShoppingCart);
  }

}
