import { NgModule } from '@angular/core';
import { LoggedComponent } from './header/logged/logged.component';
import { NoLoggedComponent } from './header/no-logged/no-logged.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ThemeCssAssetsComponent } from './theme-css-assets/theme-css-assets.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FooterModule } from './footer/footer.module';
import { OfferNavComponent } from './offer-nav/offer-nav.component';
import { ChatWhatsappComponent } from './chat-whatsapp/chat-whatsapp.component';

@NgModule({
  imports: [
    SharedModule,
    FooterModule
  ],
  declarations: [
    LoggedComponent,
    NoLoggedComponent,
    BreadcrumbComponent,
    ThemeCssAssetsComponent,
    OfferNavComponent,
    ChatWhatsappComponent
  ],
  exports: [
    LoggedComponent,
    NoLoggedComponent,
    BreadcrumbComponent,
    FooterModule,
    OfferNavComponent,
    ChatWhatsappComponent
  ]
})
export class CommonsModule { }
