export class SecurityAccessConstants {

    public static get SECURITY_ACCESS_ENDPOINT(): string {
        // Securityaccess Producción
        //return 'https://app.virginmobile.co/api/securityaccess';

        // Securityaccess Desarrollo
        return 'https://apipublic.virginmobile.co/security/api/v1/security';
    }

    public static get URLSECURITYACCESS(): string {
        // Securityaccess Producción
        //return 'https://app.virginmobile.co/api/securityaccess';

        // Securityaccess Desarrollo
        return 'https://apipublic.virginmobile.co/security/api/v1/security';
    }

    // si modifica este valor también se debe cambiar en el index.html
    public static get RECAPTCHA_SITE_KEY(): string {
      return '6LfcTfopAAAAAGXgIednlPo0Xrba0YdjBSaUafdO';
    }

    public static get RECAPTCHA_ACTION(): string {
      return 'LOGIN';
    }

    public static get URL_API_FACEBOOK(): string {
        return 'https://graph.facebook.com/v4.0/me?fields=picture.width(200).height(200)%2Cid%2Cname%2Cemail&access_token=';
    }

}
