
import { LOCALE_ID, NgModule, ErrorHandler} from '@angular/core';
import { SharedModule } from './modules/shared/shared.module';
import { AppComponent } from './app.component';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { JwtHttpInterceptor, DEFAULT_TIMEOUT } from './utils/interceptors/authorization/jwt-http-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './components/home/home.module';
import { DatePipe, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonsModule } from './components/commons/commons.module';
import { EventsModule } from 'angular4-events';
import { RedirectGuard } from './utils/RedirectGuard';
import es from '@angular/common/locales/es-CO';
import { AirbrakeErrorHandler } from './airbrake-error-handler';
import { ChatWhatsappComponent } from './components/commons/chat-whatsapp/chat-whatsapp.component';

registerLocaleData(es, 'es');

// ======= To get access token
export function tokenGetter() {
  return localStorage.getItem('access_token');
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonsModule,
    SharedModule,
    AppRoutingModule,
    HomeModule,
    EventsModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
  ],
  providers: [
    RedirectGuard,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 31000 },
   // { provide: ErrorHandler, useClass: AirbrakeErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
