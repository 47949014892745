/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fo-principal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../fo-nav/fo-nav.component.ngfactory";
import * as i3 from "../fo-nav/fo-nav.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../service/security-access/user.service";
import * as i6 from "../fo-credits/fo-credits.component.ngfactory";
import * as i7 from "../fo-credits/fo-credits.component";
import * as i8 from "./fo-principal.component";
var styles_FoPrincipalComponent = [i0.styles];
var RenderType_FoPrincipalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FoPrincipalComponent, data: {} });
export { RenderType_FoPrincipalComponent as RenderType_FoPrincipalComponent };
export function View_FoPrincipalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fo-nav", [], null, null, null, i2.View_FoNavComponent_0, i2.RenderType_FoNavComponent)), i1.ɵdid(1, 114688, null, 0, i3.FoNavComponent, [i4.Router, i5.UserService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-fo-credits", [], null, null, null, i6.View_FoCreditsComponent_0, i6.RenderType_FoCreditsComponent)), i1.ɵdid(3, 114688, null, 0, i7.FoCreditsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
export function View_FoPrincipalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fo-principal", [], null, null, null, View_FoPrincipalComponent_0, RenderType_FoPrincipalComponent)), i1.ɵdid(1, 114688, null, 0, i8.FoPrincipalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FoPrincipalComponentNgFactory = i1.ɵccf("app-fo-principal", i8.FoPrincipalComponent, View_FoPrincipalComponent_Host_0, {}, {}, []);
export { FoPrincipalComponentNgFactory as FoPrincipalComponentNgFactory };
