import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError } from 'rxjs';
import { SecurityAccessConstants } from '../../utils/constants/endpoints/security-access/security-access-constants';
import { DataService } from '../product-catalog/data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@auth0/angular-jwt/src/jwthelper.service";
import * as i3 from "../product-catalog/data.service";
export class UserService {
    constructor(http, jwtHelper, dataService) {
        this.http = http;
        this.jwtHelper = jwtHelper;
        this.dataService = dataService;
    }
    loginUser(loginUserDto) {
        this.logout();
        return this.http.post(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/login-user', loginUserDto);
        /* .pipe(
          tap(
            (response) => {
              console.log(response);
            }
          ),
          catchError(this.handleError)
        ); */
    }
    loginSocialNetwork(socialNetworkTokenVericationDto) {
        this.logout();
        return this.http.post(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/login-social-network', socialNetworkTokenVericationDto);
        /* .pipe(
          tap(
            (response) => {
              console.log(response);
            }
          ),
          catchError(this.handleError)
        ); */
    }
    forgotPassword(userResetDto) {
        return this.http.post(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/forgot-password', userResetDto);
    }
    validateCode(userDto) {
        return this.http.post(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/validate-code', userDto);
    }
    updatePassword(userDto) {
        return this.http.post(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/update-password', userDto);
    }
    enableAccount(userResetDto) {
        return this.http.post(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/enable-account', userResetDto);
    }
    resendRegisterCode(userResetDto) {
        return this.http.post(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/resend/register', userResetDto);
    }
    saveToken(token, nickname, imageUrl) {
        localStorage.setItem('access_token', token);
        console.log(' Agrega el token a storage');
        const decodedToken = this.jwtHelper.decodeToken(token);
        if (decodedToken) {
            if (decodedToken.names != null) {
                localStorage.setItem('names', decodedToken.names);
                localStorage.setItem('nickname', decodedToken.names);
            }
            if (decodedToken.lastnames != null) {
                localStorage.setItem('lastnames', decodedToken.lastnames);
                localStorage.setItem('nickname', decodedToken.names + ' ' + decodedToken.lastnames);
            }
            localStorage.setItem('username', decodedToken.sub);
            if (imageUrl != null) {
                localStorage.setItem('imageUrl', imageUrl);
            }
            if (nickname) {
                localStorage.setItem('nickname', nickname);
            }
            console.log(' Agrega data adicional a storage');
        }
    }
    signup(userDto) {
        return this.http.post(SecurityAccessConstants.SECURITY_ACCESS_ENDPOINT + '/users/signup', userDto);
    }
    isAuthenticated() {
        const token = localStorage.getItem('access_token');
        if (token == null) {
            return false;
        }
        else {
            if (this.jwtHelper.isTokenExpired(token)) {
                this.logout();
                return false;
            }
            return true;
        }
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error('An error occurred:', error);
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // console.error(
            //   `Backend returned code ${error.status}, ` +
            //   `body was: ${error.error}`);
            // console.dir(error);
        }
        return throwError(error);
    }
    /**
     * Function to logout a user from the system
     */
    logout() {
        console.log(' on logout Service');
        // --- Variables que pueden seguir al hacer un logout
        const selectedCatatalog = this.dataService.getSelectedCatalogItem();
        const selectedSimCatatalog = this.dataService.getSelectedSimCatalogItem();
        // --- Lista de adicionales
        const aditionalsList = this.dataService.getAddOnList();
        // --- descuento seleccionado
        const selectedDiscount = this.dataService.getSelectedDiscount();
        // --- codigo promocional
        const promotionalCode = this.dataService.getPromotionalCode();
        localStorage.clear();
        sessionStorage.clear();
        this.dataService.updateSelectedCatalogItem(selectedCatatalog);
        this.dataService.updateSelectedSimCatalogItem(selectedSimCatatalog);
        this.dataService.updateAddOnList(aditionalsList);
        this.dataService.updateSelectedDiscount(selectedDiscount);
        this.dataService.updatePromotionalCode(promotionalCode);
    }
    getApiFacebook(token) {
        return this.http.get(SecurityAccessConstants.URL_API_FACEBOOK + token);
    }
    getSecurityAccesTokenINew() {
        return this.http.get(SecurityAccessConstants.URLSECURITYACCESS + '/users/key-services');
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JwtHelperService), i0.ɵɵinject(i3.DataService)); }, token: UserService, providedIn: "root" });
