import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class MetaService {

    constructor(private meta: Meta) { }

    updateMetadata(desc: string, keywords: string) {
        if(desc){
            this.meta.updateTag({ name: 'description', content: desc });
        }else{
            this.meta.removeTag("name='description'");
        }
        if(keywords){
            this.meta.updateTag({ name: 'keywords', content: keywords });
        }else{
            this.meta.removeTag("name='keywords'");
        }
    }
}