import { Routes } from "@angular/router";
import { SignupComponent } from "./components/home/signup/signup/signup.component";
const ɵ0 = () => import("./components/promo/promo.module.ngfactory").then((m) => m.PromoModuleNgFactory), ɵ1 = () => import("./components/inicio/inicio.module.ngfactory").then((m) => m.InicioModuleNgFactory), ɵ2 = {
    breadcrumb: "",
    title: "Compra - Virgin Mobile Colombia",
}, ɵ3 = () => import("./components/compra/compra.module.ngfactory").then((m) => m.CompraModuleNgFactory), ɵ4 = {
    breadcrumb: "Compra",
    title: "Compra - Virgin Mobile Colombia",
}, ɵ5 = () => import("./components/product-catalog/product-catalog.module.ngfactory").then((m) => m.ProductCatalogModuleNgFactory), ɵ6 = {
    breadcrumb: "Planes",
    title: "Compra - Virgin Mobile Colombia",
    description: "¡Los Antiplanes son como un plan de los otros, pero bien! Están compuestos de todos los recursos: Minutos, Megas,recursos ilimitados y mensajes de textos. Puedes activarlos cuando quieras o de acuerdo con tu bolsillo. En Virgin Mobile eres libre y no te atamos a nada porque somos una telefonía 100% prepago.!",
    keywords: "Antiplanes, recursos, Virgin Mobile, Prepago, Telefonía móvil, internet movil ilimitado, $75.000, Internet ilimitado Virgin, Ilimitado, ilimitado, ilimitado",
}, ɵ7 = () => import("./components/product-catalog/product-catalog.module.ngfactory").then((m) => m.ProductCatalogModuleNgFactory), ɵ8 = {
    breadcrumb: "Bolsas",
    title: "Compra - Virgin Mobile Colombia",
    description: "Las Bolsas de Voz, datos y chat de Whatsapp  son productos especialmente para hacer llamadas, navegar o chatear. Hay de todos los colores y sabores desde $1.000 pesitos hasta $40.000.",
    keywords: "bolsas, paquetes, Whatsapp, chat, datos, voz",
}, ɵ9 = {
    breadcrumb: "sims > Sim Línea nueva",
    title: "Compra - Virgin Mobile Colombia",
    description: "Puedes pedir tu SIM Virgin Mobile de forma fácil, en nuestra página web.  Si eres un cliente nuevo, te damos la opción de comprar directamente un producto que viene con la Sim Card y el envío GRATIS, sólo pagas el valor del Plan.",
    keywords: "compra, sims, sim card, Virgin Mobile, Prepago, Telefonía móvil, internet movil ilimitado, $75.000, Internet ilimitado Virgin, Ilimitado, ilimitado, ilimitado",
}, ɵ10 = {
    breadcrumb: "sims > sim de reposición",
    title: "Compra - Virgin Mobile Colombia",
    description: "Puedes pedir tu SIM Virgin Mobile de forma fácil, en nuestra página web.  Si eres un cliente nuevo, te damos la opción de comprar directamente un producto que viene con la Sim Card y el envío GRATIS, sólo pagas el valor del Plan.",
    keywords: "compra, sims, sim card, Virgin Mobile, Prepago, Telefonía móvil, internet movil ilimitado, $75.000, Internet ilimitado Virgin, Ilimitado, ilimitado, ilimitado",
}, ɵ11 = () => import("./components/recargas/recargas.module.ngfactory").then((m) => m.RecargasModuleNgFactory), ɵ12 = {
    breadcrumb: "Recargas",
    title: "Compra - Virgin Mobile Colombia",
    description: "Es nuestro producto básico. Puedes recargar cualquier valor a partir de $1.000 y hasta $100.000 por nuestra web/app. Si no tienes tarjeta , tranqui puedes pedir por nuestra web/app e ir un punto Efecty y pagar.",
    keywords: "Planes, Efecty, Virgin Mobile, Prepago, Telefonía móvil",
}, ɵ13 = () => import("./components/checkout-guest/checkout-guest.module.ngfactory").then((m) => m.CheckoutGuestModuleNgFactory), ɵ14 = {
    breadcrumb: "CARRITO DE COMPRA",
    title: "Compra - Virgin Mobile Colombia",
}, ɵ15 = () => import("./components/selfcare/selfcare.module.ngfactory").then((m) => m.SelfCareModuleNgFactory), ɵ16 = {
    breadcrumb: "MI CUENTA",
    title: "Mi Cuenta - Virgin Mobile Colombia",
}, ɵ17 = () => import("./components/knowledge-base/knowledge-base.module.ngfactory").then((m) => m.KnowledgeBaseModuleNgFactory), ɵ18 = {
    breadcrumb: "INICIO > AYUDA",
    title: "Ayuda - Virgin Mobile Colombia",
}, ɵ19 = () => import("./components/leads/leads.module.ngfactory").then((m) => m.LeadsModuleNgFactory), ɵ20 = {
    breadcrumb: "PÁSATE",
    title: "Virgin Mobile Colombia",
}, ɵ21 = () => import("./components/sim-tracking/sim-tracking.module.ngfactory").then((m) => m.SimTrackingModuleNgFactory), ɵ22 = {
    breadcrumb: "SEGUIMIENTO DE SIM CARD",
    title: "Virgin Mobile Colombia",
}, ɵ23 = () => import("./components/customize/customize.module.ngfactory").then((m) => m.CustomizeModuleNgFactory), ɵ24 = {
    breadcrumb: "Personaliza tu Plan",
    title: "Virgin Mobile Colombia",
}, ɵ25 = () => import("./components/loyalty-microsite/loyalty-microsite.module.ngfactory").then((m) => m.LoyaltyMicrositeModuleNgFactory), ɵ26 = {
    breadcrumb: "Información importante para usuarios  >  Promociones de fidelización y retención",
    title: "Virgin Mobile Colombia",
};
/*import { KnowledgeBaseComponent } from './components/knowledge-base/knowledge-base.component';*/
const routes = [
    { path: "", redirectTo: "ingreso", pathMatch: "full" },
    {
        path: "promo/:txid",
        loadChildren: ɵ0,
    },
    {
        path: "",
        loadChildren: ɵ1,
        data: ɵ2,
    },
    {
        path: "compra",
        loadChildren: ɵ3,
        data: ɵ4,
    },
    {
        path: "antiplanes",
        loadChildren: ɵ5,
        data: ɵ6,
    },
    {
        path: "bolsas",
        loadChildren: ɵ7,
        data: ɵ8,
    },
    {
        path: "sim-linea-nueva",
        loadChildren: "./components/sims-catalog/sims-catalog.module#SimsCatalogModule",
        data: ɵ9,
    },
    {
        path: "sim-de-reposicion",
        loadChildren: "./components/sims-catalog/sims-catalog.module#SimsCatalogModule",
        data: ɵ10,
    },
    /* {
      path: 'compra-tu-sim',
      canActivate: [RedirectGuard],
      component: RedirectGuard,
      data: {
        externalUrl: 'https://www.virginmobile.co/sims/'
      }
    }, */
    {
        path: "recargas",
        loadChildren: ɵ11,
        data: ɵ12,
    },
    {
        path: "carrito-de-compra",
        loadChildren: ɵ13,
        data: ɵ14,
    },
    { path: "signup", component: SignupComponent },
    {
        path: "mi-cuenta",
        loadChildren: ɵ15,
        data: ɵ16,
    },
    /* {
        path: 'sim-repo',
        loadChildren: () => import('./components/sim-repo/sim-repo.module').then(m => m.SimRepoModule),
        data: {
            breadcrumb: 'REPOSICIÓN DE SIM',
            title: 'Mi Cuenta - Virgin Mobile Colombia',
        }
    }, */
    {
        path: "ayuda",
        loadChildren: ɵ17,
        data: ɵ18,
    },
    {
        path: "pasate",
        loadChildren: ɵ19,
        data: ɵ20,
    },
    {
        path: "seguimiento-de-sim-card/:regid",
        loadChildren: ɵ21,
        data: ɵ22,
    },
    {
        path: "personaliza",
        loadChildren: ɵ23,
        data: ɵ24,
    },
    {
        path: "promociones-de-fidelizacion",
        loadChildren: ɵ25,
        data: ɵ26,
    },
    { path: "**", redirectTo: "/ingreso", pathMatch: "full" },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26 };
