/**
  * Archivo de constantes utilizadas en los componentes para la renderización de los catálogos
  *
  * @author SW CONSULTING
  *         Ricardo Ayala Martínez <br>
  *         Email: rayala@swat-it.co <br>
  */

/**
 * Constantes para el consumo del servicio de consulta del catálogo
 */
export const CHANNEL = '1';
export const CHANNEL_PERSONALIZA = '11';
export const CHANNEL_LEADS = '10';
export const BRAND = '1';

/**
 * Constantes para los nombres generales de los productos que pueden ser incluidos
 * en las cajas de oferta
 */
export const LLAMADAS_OPERADORES = 'LLAMADAS OTROS OPERADORES';
export const FACEBOOK = 'FACEBOOK';
export const DATOS = 'DATOS';
export const WHATSAPP = 'WHATSAPP';
export const LLAMADAS_VIRGIN = 'LLAMADAS ENTRE VIRGIN';
export const SMS = 'SMS';
export const LOLA_MUSIC = 'LOLA MUSIC';
export const UNLIMITED_VALUE = 'ilimitado';
export const UNLIMITED_VALUE_S = 'ilimitados';
export const PLUS_CONECTOR = '+';
/** Constante para el tipo de producto de adicional */
export const PRODUCT_TYPE_ADITIONAL = 'BUNDLE';
/** Constante para el control del tamaño de las cajas de oferta */
export const RESIZE_MIN_WIDTH = 767;
/** Mensajes de carga y error en la consulta de catalogos */
export const MESSAGE_LOADING = '¡Estamos haciendo magia!';
export const MESSAGE_ERROR = '¿Te quedaste sin gasolina? Verifica tu conexión a internet y vuelve a intentar plis';

export const ANTIPLANES_CATEGORY_ID = 1;
export const BOLSAS_CATEGORY_ID = 2;
export const RECARGAS_CATEGORY_ID = 4;
export const SIMS_CATEGORY_ID = 3;
export const SIMS_REPO_CATEGORY_ID = 5;

export const CATEGORY_MAP = {
  'antiplanes': ANTIPLANES_CATEGORY_ID,
  'bolsas': BOLSAS_CATEGORY_ID,
  'recargas': RECARGAS_CATEGORY_ID,
  'sim-linea-nueva': SIMS_CATEGORY_ID,
  'sim-de-reposicion': SIMS_REPO_CATEGORY_ID
};

export const TARIFF_CATEGORY_NAME = "TARIFF";
export const BUNDLE_CATEGORY_NAME = "BUNDLE";
export const TOPUP_CATEGORY_NAME = "TOPUP";
export const SIM_NEW_LINE_CATEGORY_NAME = "SIM_NEW_LINE";
export const SIM_REPLACEMENT_CATEGORY_NAME = "SIM_REPO";
export const PORTABILITY_CATEGORY_NAME = "PORTABILITY";
export const UNKNOWN_CATEGORY_NAME = "UNKNOWN";

export const CATEGORY_NAME_MAP = {
  'antiplanes': TARIFF_CATEGORY_NAME,
  'bolsas': BUNDLE_CATEGORY_NAME,
  'recargas': TOPUP_CATEGORY_NAME,
  'sim-linea-nueva': SIM_NEW_LINE_CATEGORY_NAME,
  'sim-de-reposicion': SIM_REPLACEMENT_CATEGORY_NAME
};

export const SIM_REPLACEMENT_DATA = {
  "info": 'Es solo para reemplazar otra SIM Virgin Mobile. Cuando te llegue la SIM no la actives, de una inicia sesión ve a "Mi SIM > Recuperar mi número" y haz el proceso de reposición tu mismito.',
  "example": 'Por ejemplo: Si se te dañó, perdió, te la quitaron o quieres cambiar de SIM Card y seguir disfrutando de Virgin Mobile Colombia.',
  "dispatch": 'La SIM se envía a la dirección que tienes registrada en tu cuenta.',
}

export const EXPIRY_UNITS = {
  'DAYS': 'DAYS',
  'MONTHS': 'MONTHS',
}

export const CONVERT_CATEGORY_NAME_MAP = {
  [TARIFF_CATEGORY_NAME]: 'Plan',
  [BUNDLE_CATEGORY_NAME]: 'Bolsa',
}

export const ROUTE_INICIO = '/';
