<router-outlet name="header">
    <app-no-logged></app-no-logged>
</router-outlet> 
<div class="container-content">
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
    <app-fo-principal class="footer-style d-block"></app-fo-principal>
    <!--menu oferta flotante pasar a componente-->
    <app-offer-nav></app-offer-nav>
</div>
<app-chat-whatsapp></app-chat-whatsapp>