/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./offer-nav.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./offer-nav.component";
var styles_OfferNavComponent = [i0.styles];
var RenderType_OfferNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfferNavComponent, data: {} });
export { RenderType_OfferNavComponent as RenderType_OfferNavComponent };
export function View_OfferNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["id", "container-oferta-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "menu-oferta-flotante"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 24, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["routerLink", "/antiplanes"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "gradient-icon icon-antiplanes"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PLANES"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [["routerLink", "/bolsas"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "gradient-icon icon-bolsas"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["BOLSAS"])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["routerLink", "/recargas"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "span", [["class", "gradient-icon icon-recargas"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["RECARGAS"])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "a", [["routerLink", "/sim-linea-nueva"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "span", [["class", "gradient-icon icon-compra-sim"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["COMPRA TU SIM"])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "a", [["href", "https://www.virginmobile.co/inicio/pasate/portabilidad/"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "span", [["class", "gradient-icon icon-porta"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PORTABILIDAD"]))], function (_ck, _v) { var currVal_2 = "/antiplanes"; _ck(_v, 5, 0, currVal_2); var currVal_5 = "/bolsas"; _ck(_v, 10, 0, currVal_5); var currVal_8 = "/recargas"; _ck(_v, 15, 0, currVal_8); var currVal_11 = "/sim-linea-nueva"; _ck(_v, 20, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 10).target; var currVal_4 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 15).target; var currVal_7 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 20).target; var currVal_10 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_9, currVal_10); }); }
export function View_OfferNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-offer-nav", [], null, null, null, View_OfferNavComponent_0, RenderType_OfferNavComponent)), i1.ɵdid(1, 114688, null, 0, i4.OfferNavComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OfferNavComponentNgFactory = i1.ɵccf("app-offer-nav", i4.OfferNavComponent, View_OfferNavComponent_Host_0, {}, {}, []);
export { OfferNavComponentNgFactory as OfferNavComponentNgFactory };
