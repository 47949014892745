/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./top-content/top-content.component.ngfactory";
import * as i3 from "./top-content/top-content.component";
import * as i4 from "./img-reference/img-reference.component.ngfactory";
import * as i5 from "./img-reference/img-reference.component";
import * as i6 from "./register/register.component.ngfactory";
import * as i7 from "./register/register.component";
import * as i8 from "./signup.component";
var styles_SignupComponent = [i0.styles];
var RenderType_SignupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignupComponent, data: {} });
export { RenderType_SignupComponent as RenderType_SignupComponent };
export function View_SignupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "sign-up-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "section", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-top-content", [], null, null, null, i2.View_TopContentComponent_0, i2.RenderType_TopContentComponent)), i1.ɵdid(5, 114688, null, 0, i3.TopContentComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "section", [["class", "col-md-5 wrapper-container left-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-img-reference", [["class", "container-content middle-position"]], null, null, null, i4.View_ImgReferenceComponent_0, i4.RenderType_ImgReferenceComponent)), i1.ɵdid(8, 114688, null, 0, i5.ImgReferenceComponent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "section", [["class", "col-md-7 wrapper-container right-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-register", [["class", "container-content middle-position"]], null, null, null, i6.View_RegisterComponent_0, i6.RenderType_RegisterComponent)), i1.ɵdid(11, 114688, null, 0, i7.RegisterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 5, 0); _ck(_v, 8, 0); _ck(_v, 11, 0); }, null); }
export function View_SignupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signup", [], null, null, null, View_SignupComponent_0, RenderType_SignupComponent)), i1.ɵdid(1, 114688, null, 0, i8.SignupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignupComponentNgFactory = i1.ɵccf("app-signup", i8.SignupComponent, View_SignupComponent_Host_0, {}, {}, []);
export { SignupComponentNgFactory as SignupComponentNgFactory };
