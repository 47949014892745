<div class="breadcrumb">
  <div class="container">
    <ul>
      <li >
        <span (click)="goTo()" routerLinkActive="router-link-active">
          Inicio
        </span>
      </li>
      <li *ngFor="let breadcrumb of breadcrumbs">
        <span [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
          {{ breadcrumb.label }}
        </span>
      </li>
    </ul>
  </div>
</div>