import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { AppConstants } from '../../constants/app-constants';
import { KnowledgeBaseConstants } from '../../constants/endpoints/knowledge-base/knowledge-base-constants';
import { environment } from 'src/environments/environment';
import { URL_ENDPOINT_PORTABILITY_REST_OLD } from '../../constants/endpoints/portability/portability.constant';
import { SecurityAccessConstants } from '../../constants/endpoints/security-access/security-access-constants';
import { URL_ENDPOINT_BACKUP_CIFIN, URL_IDENTIFIER } from '../../constants/endpoints/cifin/cifin.constant';
import { BackendConstants } from '../../constants/endpoints/backend/backend.constants';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {

    constructor(
        @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
        private tokenService: HttpXsrfTokenExtractor
    ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const timeoutValue = Number(request.headers.get('timeout')) || this.defaultTimeout;
        let token = localStorage.getItem('access_token');
        let clone: HttpRequest<any>;

        if (request.url === AppConstants.URL_IP_ADDRESS || request.url.includes(environment.ApiGoogleMaps)
            || request.url === SecurityAccessConstants.URLSECURITYACCESS + '/users/key-services') {
            clone = request.clone({
                setHeaders: {
                }
            });
            return next.handle(clone);
        }

        if (request.url.includes(KnowledgeBaseConstants.ENDPOINT + '/searchquestion')) {
            token = sessionStorage.getItem('token_knowledge');
            clone = request.clone({
                setHeaders: {
                    Accept: `application/json`,
                    'Content-Type': `application/json`,
                    Authorization: `JWT ${token}`
                }
            });
            return next.handle(clone);
        }

        if (request.url.includes(URL_ENDPOINT_BACKUP_CIFIN) ||
            request.url.includes(URL_IDENTIFIER)) {
            const tokenCookie = localStorage.getItem('_hsvca');
            clone = request.clone({
                setHeaders: {
                    Accept: `application/json`,
                    'Content-Type': `application/json`,
                    'CSRF-Token': tokenCookie ? tokenCookie : `10df18ab-c98f-4958-8b31-d1e86b9f65e5`
                }
            });
            return next.handle(clone);
        }

        if (token) {
            if (!request.headers.has('Authorization')){
              //solo agregar token si el request no lo tiene
              clone = request.clone({
                  setHeaders: {
                      Accept: `application/json`,
                      'Content-Type': `application/json`,
                      Authorization: `Bearer ${token}`
                  }
              });
            }
            else {
              clone = request;
            }
        } else {
          if (
            request.url.includes(URL_ENDPOINT_PORTABILITY_REST_OLD) ||
            request.url.includes(BackendConstants.URL_ENDPOINT_BACKEND) ||
            request.url.includes(BackendConstants.URL_ENDPOINT_BACKEND_SECURE)
            ) {
            clone = request.clone({
              setHeaders: {
                Accept: `application/json`,
                'Content-Type': `application/json`,
                Authorization: `Bearer ` + AppConstants.TOKEN_GENERICO
              }
            });
          } else {
            clone = request.clone({
              setHeaders: {
                Accept: `application/json`,
                'Content-Type': `application/json`
              }
            });
          }
        }
        return next.handle(clone).pipe(timeout(timeoutValue));
    }
}
