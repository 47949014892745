<div class="social container-fluid mt-3 mb-3" style="position: relative; margin-top: 2rem!important;">
  <div class="d-none d-sm-none d-md-block">
    <div class="virgin-desc container">
      <img src="/inicio/assets/footer/virgin.png" alt="" width="100">
      <div>
        <p style="padding: 0; font-weight: bold">Todos los derechos reservados ©2024 </p>
        <p style="padding: 0; font-weight: bold">Virgin Mobile Colombia.</p>
      </div>
    </div>
  </div>
  <h5 style="text-transform: none; margin-top: -70px;" class="d-flex justify-content-center">Síguenos en Redes</h5>
  <ul class="d-flex justify-content-center et-social-icons">
    <li class="et-social-icon">
      <a href="https://www.facebook.com/virginmobileco" target="_blank" aria-label="facebook">
        <span class="icon-facebook-positive"></span>
      </a>
    </li>
    <li class="et-social-icon">
      <a href="https://twitter.com/Virgin_MobileCo" target="_blank" aria-label="twitter">
        <img src="/inicio/assets/footer/x.png" width="18" alt="">

      </a>
    </li>
    <li class="et-social-icon">
      <a href="https://www.youtube.com/user/VirginMobileCo" target="_blank" aria-label="youtube">
        <span class="icon-youtube"></span>
      </a>
    </li>
    <li class="et-social-icon">
      <a href="https://www.instagram.com/virginmobileco/" target="_blank" aria-label="instagram">
        <span class="icon-instagram" style="font-size: 26px; line-height: 14px; vertical-align: top;"></span>
      </a>
    </li>
  </ul>

</div>

<div class=" container-fluid footer-bottom ">
  <div class="row">
    <div class="container w-100">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <div class="d-block d-sm-block d-md-none col-12 d-flex justify-content-center flex-column align-items-center">
            <img src="/inicio/assets/footer/virgin.png" alt="" width="100">
            <p style="padding: 0; font-weight: bold">Todos los derechos reservados ©2024 </p>
            <p style="padding: 0; font-weight: bold">Virgin Mobile Colombia.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
