export class KnowledgeBaseConstants {

    public static get ENDPOINT(): string {
        // return 'http://3.90.159.214/search_virgin';
        // return 'http://localhost:8081/knowledge-base';
        return 'https://app.virginmobile.co/api/knowledge-base';
        // return 'https://www.virginmobile.co/api/kb_search';
    }


}
