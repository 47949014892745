import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MetaService } from './service/product-catalog/meta.service';
import { VersionCheckService } from './service/util/version-check.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { CanonicalLinkService } from './service/util/canonical-link.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public router: Router,
    private metaService: MetaService,
    private activatedRoute: ActivatedRoute,
    private versionCheckService: VersionCheckService,
    private titleService: Title,
    private canonicalLinkService: CanonicalLinkService) { }

  ngOnInit() {
    console.log('environment', environment);
    console.debug = function (name, data) {
      if (name && data) {
        console.log('%c' + name + ':' , 'color: white; background-color: #333333; padding: 2px 4px; border-radius: 2px', data);
      } else if (typeof name !== 'object' && !Array.isArray(name) ) {
        console.log('%c' + name, 'color: white; background-color: #D24919; padding: 2px 4px; border-radius: 2px');
      } else {
        console.log(name);
      }
    };

    if (environment.production) {
      console.log('PRODUCTION');
      // --- Habilitar lineas console para produccion - OCULTAR LOG
      console.info = function () { };
      console.log = function () { };
      console.debug = function () { };
      // console.error = function () { };
      console.warn = function () { };
    }

    this.versionCheckService.initVersionCheck(environment.versionCheckURL);

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this.canonicalLinkService.createOrUpdateCanonicalURL();
        this.metaService.updateMetadata(event['description'], event['keywords']);
        this.titleService.setTitle(event['title'] ? event['title'] : 'Compra - Virgin Mobile Colombia');
      });
  }
}
