import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VersionCheckService {
    constructor(http) {
        this.http = http;
        // this will be replaced by actual hash post-build.js
        this.currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    }
    /**
    * Checks in every set frequency the version of frontend application
    * @param url
    * @param {number} frequency - in milliseconds, defaults to 30 minutes
    */
    initVersionCheck(url, frequency = 1000 * 60 * environment.timerCheckVersion) {
        setInterval(() => {
            console.log('interval', new Date());
            this.checkVersion(url);
        }, frequency);
        this.checkVersion(url);
    }
    /**
    * Will do the call and check if the hash has changed or not
    * @param url
    */
    checkVersion(url) {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .subscribe((response) => {
            const hash = response.hash;
            const hashChanged = this.hasHashChanged(this.currentHash, hash);
            console.log('hash-local', this.currentHash);
            console.log('hash-change', hash);
            // If new version, do something
            if (hashChanged) {
                window.location.reload();
                // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                // for an example: location.reload();
            }
            // store the new hash so we wouldn't trigger versionChange again
            // only necessary in case you did not force refresh
            this.currentHash = hash;
        }, (err) => {
            console.error(err, 'Could not get version');
        });
    }
    /**
    * Checks if hash has changed.
    * This file has the JS hash, if it is a different one than in the version.json
    * we are dealing with version change
    * @param currentHash
    * @param newHash
    * @returns {boolean}
    */
    hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }
        return currentHash !== newHash;
    }
}
VersionCheckService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VersionCheckService_Factory() { return new VersionCheckService(i0.ɵɵinject(i1.HttpClient)); }, token: VersionCheckService, providedIn: "root" });
