import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fo-credits',
  templateUrl: './fo-credits.component.html',
  styleUrls: ['./fo-credits.component.css']
})
export class FoCreditsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
