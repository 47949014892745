/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./img-reference.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./img-reference.component";
var styles_ImgReferenceComponent = [i0.styles];
var RenderType_ImgReferenceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImgReferenceComponent, data: {} });
export { RenderType_ImgReferenceComponent as RenderType_ImgReferenceComponent };
export function View_ImgReferenceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "container-fluid su-img-reference-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "logo"], ["src", "../../../../../assets/themepuro/img/app-referent.png"]], null, null, null, null, null))], null, null); }
export function View_ImgReferenceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-img-reference", [], null, null, null, View_ImgReferenceComponent_0, RenderType_ImgReferenceComponent)), i1.ɵdid(1, 114688, null, 0, i2.ImgReferenceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImgReferenceComponentNgFactory = i1.ɵccf("app-img-reference", i2.ImgReferenceComponent, View_ImgReferenceComponent_Host_0, {}, {}, []);
export { ImgReferenceComponentNgFactory as ImgReferenceComponentNgFactory };
