/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-whatsapp.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chat-whatsapp.component";
var styles_ChatWhatsappComponent = [i0.styles];
var RenderType_ChatWhatsappComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatWhatsappComponent, data: {} });
export { RenderType_ChatWhatsappComponent as RenderType_ChatWhatsappComponent };
export function View_ChatWhatsappComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "whatsapp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "https://wa.me/573192510001"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fab fa-whatsapp whatsapp-icon"]], null, null, null, null, null))], null, null); }
export function View_ChatWhatsappComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-whatsapp", [], null, null, null, View_ChatWhatsappComponent_0, RenderType_ChatWhatsappComponent)), i1.ɵdid(1, 114688, null, 0, i2.ChatWhatsappComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatWhatsappComponentNgFactory = i1.ɵccf("app-chat-whatsapp", i2.ChatWhatsappComponent, View_ChatWhatsappComponent_Host_0, {}, {}, []);
export { ChatWhatsappComponentNgFactory as ChatWhatsappComponentNgFactory };
