<div class="container-fluid login-right-wrapper">
  <div class="login-right-container">
    <div class="social-net">
      <p class="text-light">Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque</p>
      <h3 class="text-light">Registrate con</h3>
      <div class="social-icons-container">
        <div class="sn-ico ico-fbk"><a href="#"><i class="fab fa-facebook-f"></i></a></div>
        <div class="sn-ico ico-google"><a href="#"><i class="fab fa-google"></i></a></div>
        <div class="sn-ico ico-twitter"><a href="#"><i class="fab fa-twitter"></i></a></div>
      </div>
      <h3 class="text-light">o</h3>
      <button pButton type="button" label="CREA UNA CUENTA" class="btn btn-light cta-position"></button>
    </div>
    <div class="help">
      <a class="text-light" href="#"><i class="fas fa-question-circle"></i>¿Necesitas Ayuda?</a>
    </div>
  </div>
</div>