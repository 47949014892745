<div class="container-fluid footer-main-nav">
  <div class="row">
    <div class="container w-100">
      <div class="row justify-content-between">
        <div class="col-12 col-md-auto footer-main-nav-container ">

          <a href="https://www.virginmobile.co/somos/">
            <h5>Quiénes somos </h5>
          </a>

          <ul>
            <li><a style="font-weight: bold;color: #0a0e1c!important;"
                href="https://www.virginmobile.co/legal/">Legal</a></li>
            <li><a style="font-weight: bold;color: #0a0e1c!important;"
                href="https://www.virginmobile.co/contactanos/">Contactanos</a></li>
            <li><a style="font-weight: bold;color: #0a0e1c!important;"
                href="https://www.virginmobile.co/legal/contra-abuso/">Dignidad infantil</a></li>
            <p><a
                style="font-weight: bold;color: #0a0e1c!important; text-decoration: none; padding-bottom: 1rem; font-size: 1rem;">Oportunidades
                Virgin</a></p>
            <li><a href="https://www.virginmobile.co/quiero-ser-distribuidor/">Quiero ser distribuidor</a></li>
            <li><a href="https://virginmobile.hiringroom.com/jobs">Trabaja con nosotros</a></li>
            <p><a
                style="font-weight: bold;color: #0a0e1c!important;text-decoration: none; padding-bottom: 1rem; font-size: 1rem;">Ayuda</a>
            </p>
            <li><a href="https://www.virginmobile.co/ayuda">Preguntas frecuentes</a></li>
            <li><a href="https://www.virginmobile.co/pqrs/">PQRS</a></li>
            <li><a href="https://www.virginmobile.co/blog/">Blog</a></li>
            <li><a href="https://www.virginmobile.co/inicio/promociones-de-fidelizacion/">Promociones de fidelización y retención</a></li>
            <!--            <li><a href="/inicio/antiplanes">Planes</a></li>-->
            <!--            <li><a href="/inicio/bolsas">Bolsas</a></Como que son bien amarrados. Estoy tratando de solicitó un mac, pero me dicen que no es posible, que tocaría escalar el caso. Entonces me van a dar otro igualli>-->
            <!--            &lt;!&ndash; <li><a href="/celulares">Celulares</a></li> &ndash;&gt;-->
            <!--            <li><a href="/inicio/recargas">Recargas</a></li>-->
            <!--            <li><a href="/inicio/sim-linea-nueva">SIM Línea Nueva</a></li>-->
            <!--            <li><a href="/inicio/sim-de-reposicion">SIM de Reposición</a></li>-->
            <!--            <li><a href="https://www.virginmobile.co/pasate/activar-tu-sim/">Activa tu SIM</a></li>-->
            <!--            <li><a href="/inicio/pasate/portabilidad">Cámbiate a Virgin</a></li>-->
            <!--            <li><a href="https://www.virginmobile.co/suscribete">Suscríbete</a></li>-->
          </ul>
        </div>
        <div class="col-12 col-md-auto footer-main-nav-container">

          <h5 class="d-none d-sm-none d-md-block">Gestiona tu celu</h5>

          <div class="d-block d-sm-block d-md-none">
            <div data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
              aria-controls="collapseExample" style="margin-bottom: 1rem"
              class="d-flex align-items-center justify-content-center ">
              <h5 style="margin: 0; padding-right: 0.4rem;">Gestiona tu celu </h5>
              <img src="/inicio/assets/footer/down.png" alt="" width="10">
            </div>
          </div>
          <div class="collapse" id="collapseExample">
            <div class="card-body">
              <ul>
                <li><a href="https://www.virginmobile.co/tu-puedes/configuracion-apn/">¿No te sirven los datos?</a></li>
                <li><a href="/inicio/mi-cuenta/mis-equipos/registrar-equipo">Registra tu celu</a></li>
                <li><a href="/inicio/mi-cuenta/mis-equipos/consultar-estado-de-mi-celu">Revisa si tu celu esta
                    registrado</a></li>
                <li><a href="https://www.virginmobile.co/informacion-usuarios/celu/estados-imei/">Diferentes estados de
                    Imei</a></li>
                <li><a href="/inicio/mi-cuenta/mis-equipos/consultar-estado-de-mi-celu">Revisa si tu celu esta
                    duplicado</a>
                </li>
                <li><a href="/inicio/mi-cuenta/mis-equipos/desbloquear-equipo">Desbloquea tu celu</a></li>
                <li><a href="/inicio/mi-cuenta/mis-equipos/bloqueo">Bloquea tu celu</a></li>
                <li><a href="https://www.virginmobile.co/informacion-usuarios/celu/cesion-equipo/">Pásale tu celu a
                    quien
                    quieras</a></li>
              </ul>
            </div>
          </div>
          <ul class="d-none d-sm-none d-md-block">
            <li><a href="https://www.virginmobile.co/tu-puedes/configuracion-apn/">¿No te sirven los datos?</a></li>
            <li><a href="/inicio/mi-cuenta/mis-equipos/registrar-equipo">Registra tu celu</a></li>
            <li><a href="/inicio/mi-cuenta/mis-equipos/consultar-estado-de-mi-celu">Revisa si tu celu esta
                registrado</a></li>
            <li><a href="https://www.virginmobile.co/informacion-usuarios/celu/estados-imei/">Diferentes estados de
                Imei</a></li>
            <li><a href="/inicio/mi-cuenta/mis-equipos/consultar-estado-de-mi-celu">Revisa si tu celu esta duplicado</a>
            </li>
            <li><a href="/inicio/mi-cuenta/mis-equipos/desbloquear-equipo">Desbloquea tu celu</a></li>
            <li><a href="/inicio/mi-cuenta/mis-equipos/bloqueo">Bloquea tu celu</a></li>
            <li><a href="https://www.virginmobile.co/informacion-usuarios/celu/cesion-equipo/">Pásale tu celu a quien
                quieras</a></li>
          </ul>
        </div>

        <div class="col-12 col-md-auto footer-main-nav-container">
          <h5>Medios de pago</h5>
          <ul>
            <li>
              <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                <div>
                  <img class="footer-icon" src="/inicio/assets/footer/pse.png" alt="pse" width="30">
                  <img class="footer-icon" src="/inicio/assets/footer/nequi.png" alt="pse" width="30">
                  <img class="footer-icon" src="/inicio/assets/footer/davi_plata.png" alt="pse" width="30">
                  <img class="d-sm-block d-md-none" src="/inicio/assets/footer/efecty.png" width="50" alt="efecty">
                  <img class="d-sm-block d-md-none" src="/inicio/assets/footer/bancolombia.png" width="70" alt="Bancolombia">
                </div>
                <p class="d-none d-sm-none d-md-block"><strong>Pago electronico</strong></p>
              </div>


            </li>
            <li class="d-none d-sm-none d-md-block">
              <div class="d-flex align-items-center">

                <img class="footer-icon" src="/inicio/assets/footer/tarjeta-de-credito.png"
                  alt="tarjeta créditp y débito virtual" width="30">

                <p class="d-none d-sm-none d-md-block"><strong>Tarjeta crédito y débito virtual</strong></p>
              </div>

            </li>
            <li class="d-none d-sm-none d-md-block"><img style="margin-left: -25px;" src="/inicio/assets/footer/efecty.png"
                width="120" alt="efecty"></li>
            <li class="d-none d-sm-none d-md-block"><img style="margin-left: -25px; margin-top: -30px;"
                src="/inicio/assets/footer/bancolombia.png" width="170" alt="Bancolombia"></li>
          </ul>

        </div>
        <div class="col-12 col-md-auto footer-main-nav-container">
          <h5>Encuéntranos</h5>
          <ul class="d-flex flex-column">




            <li><a href="https://www.virginmobile.co/encuentranos/" target="_blank"><img
                  src="/inicio/assets/footer/store_virgin.png" width="170" alt="Descargala en Virgin store"></a></li>


            <li class="font-pop" style="margin: 1rem 0">
              <h6 style="text-transform: none; font-size: 1rem; font-weight: 700;">¡Descarga Nuestra app!</h6>
              <p style="font-size: 0.8rem">
                Recarga, consulta tu saldo <br>
                y más…
              </p>
            </li>


            <li><a href="https://play.google.com/store/apps/details?id=com.virginmobile.colombia" target="_blank"><img
                  src="/inicio/assets/footer/boton_google.png" width="170" alt="Descargala en Google play"></a></li>



            <li><a href="https://apps.apple.com/us/app/virgin-mobile-colombia/id986364079?l=es&ls=1"
                target="_blank"><img src="/inicio/assets/footer/app_store.png" width="170" alt="Descargala en App store"></a>
            </li>

          </ul>

          <!--          <h5>Oportunidades Virgin</h5>-->
          <!--          <ul>-->
          <!--            <li><a href="https://www.virginmobile.co/quiero-ser-distribuidor/">Quiero ser distribuidor</a></li>-->
          <!--          </ul>-->
          <!--          <h5>Ayuda</h5>-->
          <!--          <ul>-->
          <!--            <li><a href="https://www.virginmobile.co/ayuda">Preguntas frecuentes</a></li>-->
          <!--            <li><a href="https://www.virginmobile.co/pqrs">PQRS</a></li>-->
          <!--          </ul>-->



        </div>
      </div>
    </div>
  </div>
</div>
