/**
  * Constantes de par�metrizaci�n para el consumo de los servicios rest de CIFIN
  */
//export const URL_ENDPOINT_CIFIN_REST = 'https://s28ve9yu02.execute-api.us-east-1.amazonaws.com/Prod/cifin';
//dev
export const URL_ENDPOINT_CIFIN_REST = 'https://82m0b3bg61.execute-api.us-east-1.amazonaws.com/api/v1'
//prod
//export const URL_ENDPOINT_CIFIN_REST = 'https://app.virginmobile.co/api/cifin'
//export const URL_ENDPOINT_BACKUP_CIFIN = 'https://app.virginmobile.co/api/backup-cifin'
export const URL_ENDPOINT_BACKUP_CIFIN = 'https://api.virginmobile.co/backup-cifin'
export const URL_EXPERIAN = 'https://app.virginmobile.co/api/experian'
export const URL_IDENTIFIER = 'https://api.virginmobile.co/identifier/api/v1';
export const COD_INFORMACION_PARAM_VALUE = 5632;
export const MOTIVO_CONSULTA_PARAM_VALUE = 24;

