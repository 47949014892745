<header id="mainNav" class="theme-header">
  <div class="container-fluid user-nav desktop">
    <div class="container w-100 px-0">
      <ul class="top-nav-list">
        <!-- <li class="user-primary-item ">
                    <a href="https://www.virginmobile.co/bling">
                        <span class="user-nav-icon icon-bling"></span>
                    </a>
                </li> -->
        <li class="top-nav-list-item">
          <a class="menu-close" href="https://www.virginmobile.co/ayuda"
            >Ayuda</a
          >
        </li>
        <li class="top-nav-list-item top-nav-list-item-container">
          <a href="javascript:void(0)"
            >Información importante para usuarios<i
              class="icon-bold-arrow-down"
            ></i
          ></a>
          <ul class="top-nav-sub-menu">
            <li>
              <a
                class="menu-close"
                href="https://www.virginmobile.co/informacion-usuarios/cobertura/"
              >
                <span>Mapa de cobertura</span>
              </a>
            </li>
            <li>
              <a
                class="menu-close"
                href="https://www.virginmobile.co/informacion-usuarios/tarifas/"
              >
                <span>Comparador de planes y tarifas</span>
              </a>
            </li>
            <li>
              <a
                class="menu-close"
                target="_blank"
                href="https://www.virginmobile.co/wp-content/uploads/wpfiles/2019/01/FACTORES-QUE-LIMITAN-LA-VELOCIDAD-DEL-SERVICIO-DE-INTERNET.pdf"
              >
                <span>Factores de limitación de la velocidad de internet</span>
              </a>
            </li>
            <li>
              <a
                class="menu-close"
                target="_blank"
                href="https://www.virginmobile.co/wp-content/uploads/wpfiles/2019/01/Procedimiento-y-tramite-de-PQR-1.pdf"
              >
                <span>Procedimiento y trámite de pqrs</span>
              </a>
            </li>
            <li>
              <a
                class="menu-close"
                href="https://www.virginmobile.co/informacion-usuarios/medicion-indicadores/"
              >
                <span>Indicadores de calidad de servicio de internet</span>
              </a>
            </li>
            <li>
              <a
                class="menu-close"
                href="https://www.virginmobile.co/informacion-usuarios/practicas-gestion-trafico/"
              >
                <span>Prácticas de gestión de tráfico</span>
              </a>
            </li>
            <li>
              <a
                class="menu-close"
                href="https://www.virginmobile.co/informacion-usuarios/tramites-digitalizados/"
              >
                <span>Trámites digitalizados</span>
              </a>
            </li>
            <li>
              <a
                class="menu-close"
                href="https://www.virginmobile.co/inicio/promociones-de-fidelizacion/"
              >
                <span>Promociones de fidelización y retención</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="top-nav-list-item top-nav-list-item-container user-nav-desk">
          <a class="d-flex"
            ><span class="icon-user"></span
            ><span>Mi cuenta<i class="icon-bold-arrow-down login-nav"></i></span
          ></a>
          <ul class="top-nav-sub-menu login-menu" *ngIf="!isAuthenticated">
            <li><a routerLink="/ingreso">Ingreso</a></li>
            <li><a routerLink="/registro">Registro</a></li>
          </ul>
          <!--if user logged-->
          <ul class="top-nav-sub-menu login-menu" *ngIf="isAuthenticated">
            <li><a routerLink="/mi-cuenta/recursos">Zona personal</a></li>
            <li>
              <a routerLink="/ingreso" (click)="cerrarSesion()"
                >Cerrar sesión</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="container-fluid">
    <div class="container w-100 main-nav">
      <div class="logo">
        <a href="/" aria-label="Virgin Mobile Colombia logo">
          <img
            src="https://www.virginmobile.co/wp-content/uploads/wpfiles/logo-vmco.svg"
            width="66"
            alt="logo"
          />
        </a>
      </div>
      <div class="mobile-icons-nav">
        <ul class="mobile-icons-nav-list">
          <li
            class="top-nav-list-item top-nav-list-item-container user-nav-desk"
          >
            <a class="d-flex"><span class="icon-user icon-style"></span></a>
            <ul class="top-nav-sub-menu login-menu" *ngIf="!isAuthenticated">
              <li><a routerLink="/mi-cuenta">Ingreso</a></li>
              <li><a routerLink="/mi-cuenta">Registro</a></li>
            </ul>
            <!--if user logged-->
            <ul class="top-nav-sub-menu login-menu" *ngIf="isAuthenticated">
              <li><a routerLink="/mi-cuenta/recursos">Zona personal</a></li>
              <li>
                <a routerLink="/ingreso" (click)="cerrarSesion()"
                  >Cerrar sesión</a
                >
              </li>
            </ul>
          </li>
          <li class="item-first-lvl cart">
            <a
              routerLink="/carrito-de-compra/paso-1"
              class="menu-close a-cart"
              href="javascript:function void() { return false; }"
              aria-label="Carrito vacio"
              *ngIf="currentShoppingCart"
            >
              <span class="icon-add_shopping_cart icon-style"></span>
              <p class="contador-carrito">1</p>
            </a>
            <a
              routerLink="/compra"
              class="menu-close a-cart"
              href="javascript:function void() { return false; }"
              aria-label="Carrito en uso"
              *ngIf="!currentShoppingCart"
            >
              <span class="icon-add_shopping_cart icon-style"></span>
            </a>
          </li>
        </ul>
        <button
          class="btn-toggle-nav"
          id="btn-toggle-nav"
          aria-label="toggle menu"
        >
          <span class="icon-toggle-menu icon-style"></span>
        </button>
        <div class="icon-close"><span class="icon-close-menu"></span></div>
      </div>

      <nav class="top-menu-nav">
        <ul class="ul nav-first-lvl mobile-menu" id="mobile-menu">
          <!--<li *ngFor="let category of categories" [id]="category.name" class="item-first-lvl">
                        <a routerLink="/{{category.categoryUrl}}" class="menu-close" (click)="selectCategory(category.id)">-->
          <!--<img [src]="category.imageUrl" [alt]="category.name" >-->
          <!--<span>{{category.name}}</span>-->
          <!--<p>{{category.description}}</p>-->
          <!--</a>
                    </li>-->
          <li class="item-first-lvl menu-item">
            <a href="/" class="menu-close">
              <span id="span_antiplanes_01">INICIO</span>
            </a>
          </li>
          <div
            id="div_sims_01"
            class="wrapper-parent-item d-block d-lg-inline-block"
          >
            <li class="item-first-lvl parent-item-nav menu-item">
              <a
                href="javascript:void(0)"
                class="anchor-wrapper-item-nav"
                id="parent-second-lvl"
                ><span class="anchor-text">PRODUCTOS</span
                ><i class="icon-bold-arrow-down"></i
              ></a>
            </li>
            <div
              class="children-wrapper container-second-lvl"
              id="children-second-lvl"
            >
              <ul class="ul nav-second-lvl">
                <li id="antplanes" class="item-first-lvl menu-item">
                  <a
                    routerLink="/antiplanes"
                    class="menu-close"
                    (click)="selectCategory(ANTIPLANES_CATEGORY_ID)"
                  >
                    <span id="span_antiplanes_01">PLANES</span>
                  </a>
                </li>
                <li id="bolsas" class="item-first-lvl menu-item">
                  <a
                    routerLink="/bolsas"
                    class="menu-close"
                    (click)="selectCategory(BOLSAS_CATEGORY_ID)"
                  >
                    <span id="span_bolsas_01">BOLSAS</span>
                  </a>
                </li>
                <li id="recargas" class="item-first-lvl menu-item">
                  <a
                    routerLink="/recargas"
                    class="menu-close"
                    (click)="selectCategory(RECARGAS_CATEGORY_ID)"
                  >
                    <span>RECARGAS</span>
                  </a>
                </li>
                <li class="item-first-lvl parent-item-nav parent-third-lvl">
                  <a
                    href="javascript:void(0)"
                    class="anchor-wrapper-item-nav menu-item"
                    id="parent-third-lvl"
                    ><span class="anchor-text">SIM CARDS</span
                    ><i class="icon-bold-arrow-down"></i
                  ></a>
                  <div
                    class="container-second-lvl styles-children-wrapper"
                    id="children-third-lvl"
                  >
                    <ul
                      class="ul nav-second-lvl third-lvl-container styles-nav-second-lvl"
                    >
                      <li class="menu-item">
                        <a routerLink="/sim-linea-nueva" class="menu-close">
                          <!-- <span class="nav-icon icon-sim"></span> -->
                          <span>SIM LÍNEA NUEVA</span>
                        </a>
                      </li>
                      <li class="menu-item">
                        <a routerLink="/sim-de-reposicion" class="menu-close">
                          <!-- <span class="nav-icon icon-sim"></span> -->
                          <span id="span_simReposicion_01"
                            >SIM DE REPOSICIÓN</span
                          >
                        </a>
                      </li>
                      <li class="menu-item">
                        <a
                          class="menu-close"
                          href="https://www.virginmobile.co/pasate/activar-tu-sim/"
                        >
                          <!-- <span class="nav-icon icon-virgin-sim"></span> -->
                          <span>Activa tu sim</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <li class="item-first-lvl menu-item">
            <a class="menu-close" routerLink="/pasate/portabilidad">
              <!-- <span class="nav-icon icon-porta"></span> -->
              <span id="span_portabilidad_01">Cámbiate a virgin</span>
            </a>
          </li>
          <li class="item-first-lvl menu-item">
            <a class="menu-close" href="/suscribete">
              <!-- <span class="nav-icon icon-porta"></span> -->
              <span id="span_portabilidad_01">Suscríbete</span>
            </a>
          </li>
          <li class="item-first-lvl menu-item">
            <a
              class="menu-close"
              href="https://www.virginmobile.co/pasate/encuentranos/"
            >
              <!-- <span class="nav-icon icon-ubica"></span> -->
              <span>Encuéntranos</span>
            </a>
          </li>
          <li class="item-first-lvl cart desktop">
            <a
              routerLink="/carrito-de-compra/paso-1"
              class="menu-close a-cart"
              href="javascript:function void() { return false; }"
              aria-label="Carrito vacio"
              *ngIf="currentShoppingCart"
            >
              <span class="icon-add_shopping_cart"></span>
              <span>Mi carrito</span>
              <p class="contador-carrito">1</p>
            </a>
            <a
              routerLink="/compra"
              class="menu-close a-cart"
              href="javascript:function void() { return false; }"
              aria-label="Carrito en uso"
              *ngIf="!currentShoppingCart"
            >
              <span class="icon-add_shopping_cart"></span>
              <span>Mi carrito</span>
            </a>
          </li>
          <div class="container-fluid user-nav mobile">
            <div class="container px-0">
              <ul class="ul top-nav-list">
                <!-- <li class="user-primary-item ">
                                    <a href="https://www.virginmobile.co/bling">
                                        <span class="user-nav-icon icon-bling"></span>
                                    </a>
                                </li> -->
                <li class="top-nav-list-item">
                  <a class="menu-close" href="https://www.virginmobile.co/ayuda"
                    >Ayuda</a
                  >
                </li>
                <li
                  class="top-nav-list-item top-nav-list-item-container"
                  id="parent-bottom-menu"
                >
                  <a href="javascript:void(0)"
                    >Información importante para usuarios<i
                      class="icon-bold-arrow-down"
                    ></i
                  ></a>
                  <ul class="user-nav-sub-menu" id="children-bottom-menu">
                    <li>
                      <a
                        class="menu-close"
                        href="https://www.virginmobile.co/informacion-usuarios/cobertura/"
                      >
                        <span>Mapa de cobertura</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="menu-close"
                        href="https://www.virginmobile.co/informacion-usuarios/tarifas/"
                      >
                        <span>Comparador de planes y tarifas</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="menu-close"
                        target="_blank"
                        href="https://www.virginmobile.co/wp-content/uploads/wpfiles/2019/01/FACTORES-QUE-LIMITAN-LA-VELOCIDAD-DEL-SERVICIO-DE-INTERNET.pdf"
                      >
                        <span
                          >Factores de limitación de la velocidad de
                          internet</span
                        >
                      </a>
                    </li>
                    <li>
                      <a
                        class="menu-close"
                        target="_blank"
                        href="https://www.virginmobile.co/wp-content/uploads/wpfiles/2019/01/Procedimiento-y-tramite-de-PQR-1.pdf"
                      >
                        <span>Procedimiento y trámite de pqrs</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="menu-close"
                        href="https://www.virginmobile.co/informacion-usuarios/medicion-indicadores/"
                      >
                        <span
                          >Indicadores de calidad de servicio de internet</span
                        >
                      </a>
                    </li>
                    <li>
                      <a
                        class="menu-close"
                        href="https://www.virginmobile.co/informacion-usuarios/practicas-gestion-trafico/"
                      >
                        <span>Prácticas de gestión de tráfico</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </ul>
      </nav>
    </div>
  </div>
</header>
