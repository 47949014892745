import { JwtHelperService } from '@auth0/angular-jwt';
import * as i0 from "@angular/core";
import * as i1 from "@auth0/angular-jwt/src/jwthelper.service";
/**
   * Servicio para el envío de datos entre componentes
   *
   * @author SW CONSULTING
   *        Ricardo Ayala Martínez <br>
   *        Email: rayala@swat-it.co <br>
   *
   */
export class DataService {
    /**
     * Método constructor
     *
     * @author SW CONSULTING
     *        Ricardo Ayala Martínez <br>
     *        Email: rayala@swat-it.co <br>
     *
     */
    constructor(jwtHelper) {
        this.jwtHelper = jwtHelper;
    }
    /**
     * Método para obtener el username desde token
     */
    getUsername() {
        const localUser = localStorage.getItem('username');
        const token = localStorage.getItem('access_token');
        if (!token) {
            return localUser;
        }
        const decodedToken = this.jwtHelper.decodeToken(token);
        if (!decodedToken) {
            return localUser;
        }
        return decodedToken.sub;
    }
    /**
     * Método que permite actualizar el valor de la categoría seleccionada
     */
    updateSelectedCategory(category) {
        sessionStorage.setItem('categoryId', category.toString());
    }
    /**
     * Método que permite actualizar el nombre de la categoría seleccionada
     */
    updateCategorySelection(category) {
        sessionStorage.setItem('categoryName', category);
    }
    /**
     * Retorna la categoria seleccionada
     */
    getSelectedCategory() {
        return sessionStorage.getItem('categoryId');
    }
    updateSelectedCatalogItem(catalog) {
        sessionStorage.setItem('catalog', JSON.stringify(catalog));
    }
    /**
     * Método que permite setear el descuento de un catalogo
     * en el session storage
     * @param discount el descuento del catalogo (puede ser null)
     */
    updateSelectedDiscount(discount) {
        sessionStorage.setItem('discount', JSON.stringify(discount));
    }
    /**
     * Método que obtiene un descuento del session storage
     */
    getSelectedDiscount() {
        return JSON.parse(sessionStorage.getItem('discount'));
    }
    /**
     * Método que actualiza el codigo promocional
     * @param promotionalCode el codigo que quieren guardar
     */
    updatePromotionalCode(promotionalCode) {
        sessionStorage.setItem('promotionalCode', JSON.stringify(promotionalCode));
    }
    /**
     * Método que obtiene el codigo de promocion del session storage
     */
    getPromotionalCode() {
        return JSON.parse(sessionStorage.getItem('promotionalCode'));
    }
    getSelectedCatalogItem() {
        return JSON.parse(sessionStorage.getItem('catalog'));
    }
    updateSelectedSimCatalogItem(simcatalog) {
        sessionStorage.setItem('simcatalog', JSON.stringify(simcatalog));
    }
    getSelectedSimCatalogItem() {
        return JSON.parse(sessionStorage.getItem('simcatalog'));
    }
    /**
     * Método que permite actualizar los valores ingresados en la recarga
     */
    updateRechargeValues(recharge) {
        sessionStorage.setItem('recharge', JSON.stringify(recharge));
    }
    /**
     * Retorna los valores ingresados en el formulario de recargas
     */
    getRechargeValues() {
        return JSON.parse(sessionStorage.getItem('recharge'));
    }
    /**
     * Método que permite actualizar los valores ingresados en el formulatio de msisdn
     */
    updateMsisdnForm(msisdnForm) {
        console.log('updateMsisdnForm', msisdnForm);
        sessionStorage.setItem('msisdnForm', JSON.stringify(msisdnForm));
    }
    /**
     * Retorna los valores ingresados en el formulario de msisdn
     */
    getMsisdnForm() {
        const getMsisdnForm = JSON.parse(sessionStorage.getItem('msisdnForm'));
        console.log('getMsisdnForm', getMsisdnForm);
        return getMsisdnForm;
    }
    getCustomer() {
        return JSON.parse(sessionStorage.getItem('customer'));
    }
    updateCustomer(customer) {
        sessionStorage.setItem('customer', JSON.stringify(customer));
    }
    /**
     * Método que permite actualizar los valores del perfil del usuario
     */
    updateProfile(profile) {
        sessionStorage.setItem('profile', JSON.stringify(profile));
    }
    /**
     * Retorna los valores del perfil del usuario
     */
    getProfile() {
        return JSON.parse(sessionStorage.getItem('profile'));
    }
    /**
     * Método que permite actualizar la informacion del prestamo
     */
    updateLoan(loan) {
        sessionStorage.setItem('loan', loan + '');
    }
    /**
     * Retorna la informacion del prestamo
     */
    getLoan() {
        return sessionStorage.getItem('loan');
    }
    /**
     * Método que permite actualizar la informacion del metodo de pago seleccionado
     */
    updatePaymentMethod(paymentMethod) {
        sessionStorage.setItem('paymentMethod', JSON.stringify(paymentMethod));
    }
    /**
     * Retorna la informacion del prestamo
     */
    getPaymentMethod() {
        return JSON.parse(sessionStorage.getItem('paymentMethod'));
    }
    /**
     * Método que permite actualizar si la tokenizacion se realizo para la transaccion
     */
    updateTransactionTokenization(txTokenization) {
        sessionStorage.setItem('txTokenization', JSON.stringify(txTokenization));
    }
    /**
     * Retorna la informacion de tokenization por transaction
     */
    getTransactionTokenization() {
        return JSON.parse(sessionStorage.getItem('txTokenization'));
    }
    /**
     * Método que permite actualizar si el agendamiento se realizo para la transaccion
     */
    updateTransactionScheduled(txScheduled) {
        sessionStorage.setItem('txScheduled', JSON.stringify(txScheduled));
    }
    /**
     * Retorna la informacion del agendamiento por transaccion
     */
    getTransactionScheduled() {
        return JSON.parse(sessionStorage.getItem('txScheduled'));
    }
    /**
     * Método que permite actualizar la informacion de la linea seleccionada
     */
    updateLineAssociationSelected(lineAssociation) {
        localStorage.setItem('lineAssociation', JSON.stringify(lineAssociation));
    }
    /**
     * Retorna la informacion de la linea seleccionada
     */
    getLineAssociationSelected() {
        return JSON.parse(localStorage.getItem('lineAssociation'));
    }
    /**
    * Método que permite agregar la lista de AddOn
    */
    updateAddOnList(aditionalList, isPersonaliza) {
        sessionStorage.setItem('addOnList', JSON.stringify(aditionalList));
        if (isPersonaliza) {
            sessionStorage.setItem('isPersonaliza', 'true');
        }
        else {
            sessionStorage.removeItem('isPersonaliza');
        }
    }
    /**
     * Retorna la lista de AddOn seleccionado
     */
    getAddOnList() {
        return JSON.parse(sessionStorage.getItem('addOnList'));
    }
    updateLeadsProduct(product) {
        if (!product && this.getLeadsProductItem()) {
            sessionStorage.setItem('leadsProductOld', sessionStorage.getItem('leadsProduct'));
        }
        else {
            sessionStorage.setItem('leadsProductOld', null);
        }
        sessionStorage.setItem('leadsProduct', JSON.stringify(product));
    }
    getLeadsProductItem() {
        return JSON.parse(sessionStorage.getItem('leadsProduct'));
    }
    updateLeadsLogisticProduct(product) {
        sessionStorage.setItem('leadsLogisticProduct', JSON.stringify(product));
    }
    /**
     * @author Jonathan Arroyo Reina
     * @description Contiene el producto anteriormente seleccionado en el procedo de Portabilidad (Leads)
     * @returns El producto seleccionado
     */
    getLeadsProductOld() {
        return JSON.parse(sessionStorage.getItem('leadsProductOld'));
    }
    getPersonaliza() {
        return sessionStorage.getItem('isPersonaliza') ? true : false;
    }
    /**
     * Limpia todos los valores del session storage
     */
    deleteAllValues() {
        console.log('borrando cache session....');
        sessionStorage.clear();
    }
    updateLeadsRegisterID(register) {
        sessionStorage.setItem('leadsRegister', String(register));
    }
    getLeadsRegisterID() {
        return +sessionStorage.getItem('leadsRegister');
    }
    getLeadsRegister() {
        console.log('dataService getLeadsRegister', JSON.parse(sessionStorage.getItem('lead-register')));
        return JSON.parse(sessionStorage.getItem('lead-register'));
    }
    updateLeadsRegister(register) {
        console.log('dataService updateLeadsRegister', JSON.stringify(register));
        sessionStorage.setItem('lead-register', JSON.stringify(register));
    }
    /**
     * @author Jonathan Arroyo Reina
     * @description actualiza el valor de la session donde contiene el valor de la url de regreso
     * @param url url donde proviene el back
     */
    updateLeadsUrlBack(url) {
        if (url) {
            sessionStorage.setItem('lead-back-url', url);
        }
        else {
            sessionStorage.removeItem('lead-back-url');
        }
    }
    getLeadsUrlBack() {
        return sessionStorage.getItem('lead-back-url');
    }
    getCountTryLeads(msisdn) {
        if (sessionStorage.getItem('lead-count-try' + msisdn)) {
            return +sessionStorage.getItem('lead-count-try' + msisdn);
        }
        return 0;
    }
    inrementTryLeads(msisdn) {
        sessionStorage.setItem('lead-count-try' + msisdn, (this.getCountTryLeads(msisdn) + 1).toString());
    }
    resetCountTryLeads(msisdn) {
        sessionStorage.removeItem('lead-count-try' + msisdn);
    }
    setPortabilityToken(token) {
        console.debug('setPortabilityToken', token);
        sessionStorage.setItem('portability-token', token);
    }
    getPortabilityToken() {
        console.debug('getPortabilityToken', sessionStorage.getItem('portability-token'));
        return sessionStorage.getItem('portability-token').toString();
    }
    // TODO: INTB1-5 eliminar al finalizar
    updateSelectedSimCatalog(simcatalog) {
        sessionStorage.setItem('simcatalog', JSON.stringify(simcatalog));
    }
    // TODO: INTB1-5 eliminar al finalizar
    updateSelectedCatalog(catalog) {
        sessionStorage.setItem('catalog', JSON.stringify(catalog));
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.JwtHelperService)); }, token: DataService, providedIn: "root" });
