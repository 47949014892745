import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CanonicalLinkService {

  /**
   * Default constructor
   * @param dom Document para acceder a los 
   *  atributos de la pagina
   */
  constructor(@Inject(DOCUMENT) private dom) { 
  }

  /**
   * Crea o actualiza el link canonical en la pagina
   * con la url enviada o la url obtenida de la actual pagina 
   * @param url 
   */
  createOrUpdateCanonicalURL(url?:string) {
    let canURL = url == undefined ? this.dom.URL : url;

    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical !== null) {
      canonical.setAttribute("href", this.getPathFromUrl(canURL));
    } else {
      let link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(link);
      link.setAttribute('href', this.getPathFromUrl(canURL));
    }
  }

  /**
   * Elimina los query strings de la URL
   * @param url 
   * @returns url sin query strings
   */
  getPathFromUrl(url) {
    return url.split(/[?#]/)[0];
  }

}
