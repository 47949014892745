// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  versionCheckURL: '../assets/config/version.json',
  knowledgeBase_user: 'adminfinder',
  knowledgeBase_pw: 'finder2018',
  timerCheckVersion: 5, // cada 30 minutos
  FacebookId: '200854648325258',
  VersionFacebook: 'v4.0',
  GoogleId: '797801414770-12tqbuvu5qidb706556ko7rgfqfte4gt.apps.googleusercontent.com',
  UrlOrigen: window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/inicio',   //https://www.virginmobile.co/inicio', // prueba locales
  ApiGoogleMaps: 'AIzaSyDbmObgYf305xssDoV0v9Q7pQ6ddbh2PLQ',
  countRequiredProductAditional: 1,
  accessKey: '977a9e14-0a83-40e9-941c-58d5087e84d2'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
