import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/security-access/user.service';
import { AppConstants } from 'src/app/utils/constants/app-constants';
@Component({
  selector: 'app-fo-nav',
  templateUrl: './fo-nav.component.html',
  styleUrls: ['./fo-nav.component.css']
})
export class FoNavComponent implements OnInit {

  private url = 'https://docs.google.com/forms/d/e/1FAIpQLSfAAUQwuAZvC3aeMOkumiNrZLtvaCothmy75ZKQBDrDKyUsqA/viewform';

  // --- Href whatsapp constant
  WHATSAPP_HREF: string = AppConstants.WHATSAPP_HREF;

  constructor(private router: Router, public userService: UserService) { }

  ngOnInit() {
  }

  goToInicio(): void {
    if (this.userService.isAuthenticated()) {
      this.router.navigate(['/mi-cuenta/pqr/crear-pqr']);
    } else {
      this.router.navigate(['/ingreso']);
    }

  }

  goToForm(): void {
    document.location.href = this.url;
  }

  /**
   * Abre un link línea ventas
   */
  goToLinkWhatsapp() {
    const chat: any = window;
    chat.tiledesk.open();
  }

}
