/**
 * Constantes de parametrizacion para el consumo de los servicios rest de Portabilidad
 */
import { environment } from 'src/environments/environment';


// export const URL_ENDPOINT_PORTABILITY = 'https://apiauth.virginmobile.co/portability/api/v1';

let URL_ENDPOINT_PORTABILITY_ = 'https://1n2cyalf1i.execute-api.us-east-1.amazonaws.com/dev/api/v1';
if (environment.production) {
  URL_ENDPOINT_PORTABILITY_ = 'https://apiauth.virginmobile.co/portability/api/v1';
}

export const URL_ENDPOINT_PORTABILITY = URL_ENDPOINT_PORTABILITY_;

//export const URL_ENDPOINT_PORTABILITY = 'http://localhost:3000/dev/api/v1';

export const TOKEN_PORTABILITY = 'eeb8d588-d4d3-426c-9d67-629241f392f9';

export const PACKAGE_LEADS = 'PUBLIC_LEADS';

// old
// Produccion
export const URL_ENDPOINT_PORTABILITY_REST_OLD = 'https://app.virginmobile.co/api/portability';
// local
// export const URL_ENDPOINT_PORTABILITY_REST = 'http://170.10.32.149:8085/portability';
// export const URL_ENDPOINT_PORTABILITY_REST = 'http://192.10.2.29:2386/bk-portability/portability';
// Timer NIP
export const SECONDS_TIMER_NIP = 60 * 5;

// --- Estados de portabilidad en las que un usuario no puede continuar en el proceso de Leads
export const PORT_STATUS_VECTOR = [
  'PortInRequestAccepted',
  'PortInRequestScheduled',
  'PortInRequestReadyToBeScheduledResponseExpired',
  'PortInRequestSchedulingConfirmedResponseExpired',
  'PortInRequestScheduledFailed',
  'PortInRequestSchedulingConfirmed',
  'PortInRequested',
  'OK',
];
