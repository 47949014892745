import { SelectItem } from 'primeng/components/common/selectitem';
import { environment } from 'src/environments/environment';

export class AppConstants {
  /**
   * Version de la aplicacion
   */
  public static get APP_VERSION(): string {
    return '3.0.0';
  }

  /**
   * IP por defecto para las transacciones
   */
  public static get DEFAULT_IP(): string {
    return '127.0.0.1';
  }

  /**
   * URL para obtener la ip del cliente
   */
  public static get URL_IP_ADDRESS(): string {
    return 'https://jsonip.com';
  }

  /**
   * Mensaje de error de linea inactiva
   */
  public static get ERROR_MESSAGE_LINEA_INACTIVA(): string {
    return 'La línea que escribiste esta inactiva: Plis escríbenos ya mismo a nuestro RockChat para ayudarte.';
  }

  /**
   * Mensaje de error de linea no virgin
   */
  public static get ERROR_MESSAGE_LINEA_NO_VIRGIN(): string {
    return 'La línea no es Virgin Mobile, revisa y vuelve a escribirla.';
  }

  /**
   * Mensaje de error de tarjeta de credito invalida
   */
  public static get ERROR_MESSAGE_INVALID_CREDIT_CARD(): string {
    return 'La tarjeta de crédito ingresada no es válida. Por favor verifica tus datos.';
  }

  /**
   * Mensaje de error de codigo de seguridad
   */
  public static get ERROR_MESSAGE_INVALID_SECURITY_CODE(): string {
    return 'El código de seguridad de la tarjeta de crédito no es válido. Por favor verifica tus datos.';
  }

  /**
   * Mensaje de error por parametros
   */
  public static get ERROR_MESSAGE_PARAMETROS(): string {
    return 'Algo no cuadra, plis revisa los datos que estas ingresando y vuelve a intentarlo!';
  }

  /**
   * Mensaje de error fallas tecnicas
   */
  public static get ERROR_MESSAGE_FALLAS_TECNICAS(): string {
    return '¡Estamos teniendo fallas técnicas. Lo sentimos en el alma, plis intenta completar tu compra en unos minutos!';
  }

  /**
   * Mensaje de error al crear transaccion
   */
  public static get ERROR_MESSAGE_CREAR_TRANSACCION(): string {
    return "¡Que no panda el cúnico! Este método de pago está ajustando tuercas y tornillos. Plis intenta de nuevo o usa otro medio de pago.";
  }

  public static get ERROR_MESSAGE_INVALID_DATA(): string {
    return "¡Tenemos un problema con tus datos de facturación! Verifica que los datos ingresados son válidos o comunícate ahora mismo a nuestro Chat y te ayudaremos";
  }

  /**
   * Mensaje de error al crear transaccion con datos vacios
   */
  public static get ERROR_MESSAGE_CREAR_TRANSACCION_EMPTY(): string {
    return 'Algo no cuadra, plis verifica que la Información de Facturación esté completa y vuelve a intentarlo!';
  }

  /**
   * Mensaje de error al validar una linea
   */
  public static get ERROR_MESSAGE_VALIDACION_LINEA(): string {
    return 'Ups! Por favor vuelve a ingresar tu línea';
  }

  /**
   * Mensaje de informacion al tokenizar una tarjeta de credito
   */
  public static get MESSAGE_TOKENIZATION(): string {
    return 'Solo nos falta comprobar que tengas fondos en tu tarjeta crédito o débito con CVV o CVC, para esto debemos realizar un cobro minimo de ${valor}, los cuales se te devolverán en el momento que aceptemos tu tarjeta. ¿Estás de acuerdo?';
  }

  /**
   * Mensaje de informacion al tokenizar una tarjeta de credito
   */
  public static get MESSAGE_DELETEADDON(): string {
    return '¿Seguro que desea eliminarlo?';
  }

  /**
   * Mensaje de error PSE
   */
  public static get ERROR_MESSAGE_PSE_COMMUNICATION(): string {
    return 'No ha sido posible la comunicación con PSE, por favor intenta con otro medio de pago.';
  }

  /**
   * Mensaje de error TOKENIZATION
   */
  public static get ERROR_MESSAGE_TOKENIZATION_COMMUNICATION(): string {
    return 'No ha sido posible la comunicación con nuestros servidores, por favor intenta con otro medio de pago.';
  }

  /**
   * Mensaje de error TOKENIZATION CARD
   */
  public static get ERROR_MESSAGE_TOKENIZATION_CARD(): string {
    return 'No ha sido posible tokenizar tu tarjeta, por favor intenta nuevamente.';
  }

  /**
   * Mensaje de error AGENDAMIENTO
   */
  public static get ERROR_MESSAGE_AGENDAMIENTO(): string {
    return 'No ha sido posible programar tu compra, por favor intenta nuevamente.';
  }

  /**
   * Mensaje de error Timeout
   */
  public static get ERROR_MESSAGE_TIMEOUT(): string {
    return 'No ha sido posible la comunicacion con nuestros servidores. Por favor intenta nuevamente o recarga la página';
  }

  /**
   * Mensaje de error de linea existe
   */
  public static get ERROR_MESSAGE_LINEA_EXISTS(): string {
    return 'Ups! esa línea ya está asociada a tu cuenta,Intenta con otra.';
  }

  /**
   * Mensaje de error codigo sms invalido
   */
  public static get ERROR_MESSAGE_INVALID_CODE(): string {
    return '¡Ups! el código que estás ingresando para completar tu proceso de portabilidad <b>NO ES VÁLIDO</b>, por fi válida nuevamente e ingresa el código.';
  }

  /**
   * Mensaje de error valid code
   */
  public static get ERROR_MESSAGE_EVIDENT_CODE(): string {
    return 'Estamos presentando fallas técnicas, por favor intenta reenviarte el código';
  }

  /**
   * Mensaje de error valid code
   */
  public static get ERROR_MESSAGE_TRANSFER_ANTIPLAN(): string {
    return 'El plan activo de la línea no permite realizar transferencias';
  }

  /**
   * Mensaje de error valid code
   */
  public static get ERROR_MESSAGE_VIGEN_TRANSFER_ANTIPLAN(): string {
    return 'No es posible realizar la transferencia con tu plan';
  }

  /**
   * Mensaje de error valid code
   */
  public static get ERROR_MESSAGE_NO_ANTIPLAN_TRANSFER(): string {
    return 'No tienes un plan activo para hacer esta maroma';
  }

  /**
   * Mensaje de error no existe linea propietaria
   */
  public static get ERROR_MESSAGE_NO_OWNER_LINE(): string {
    return '¡UPS! Para poder hacer esta maroma, debes ser propietario de al menos 1 línea asociada a tu cuenta, para esto, ve a <a class="hyperlink d-inline-block" href="/inicio/mi-cuenta/mis-lineas">"Mis líneas"</a> y actualiza los datos de tu línea y listo.';
  }

  /**
   * Mensaje de reintento superados de codigo sms invalido
   */
  public static get ERROR_MESSAGE_COUNT_INVALID_CODE(): string {
    return '¡PILAS! Superaste el número de intentos para ingresar el código. Por fi escríbenos a Whatsapp <a href="' + this.WHATSAPP_HREF_SIM + '" target="_blank">+573195550005</a> y te ayudamos en menos de lo que canta un gallo.';
  }

  /**
   * Mensaje de error activacion fallida
   */
  public static get MESSAGE_ACIVATION_FAILED(): string {
    return '<div class=\'row\'><label>¡Hubo un problema con tu compra. Plis escríbenos ya mismo a nuestro </label>&nbsp;<a href=\'www.chat.com\'> WhatsApp</a><label>&nbsp;para ayudarte!</label></div>';
  }

  /**
   * Error Generico Activation request
   */
  public static get ERROR_MESSAGE_ACTIVATION(): string {
    return 'Ha ocurrido un error al activar tu SIM, por favor intenta nuevamente.';
  }

  /**
   * Error maximo numero NIP Request
   */
  public static get ERROR_MESSAGE_MAX_NIP_PORTABILITY(): string {
    return 'Lo sentimos, excediste el máximo de intentos de reenví­os de NIP, por favor ponte en contacto con nosotros a nuestra lí­nea de Whatsapp';
  }

  /**
   * Error Generico Nip request
   */
  public static get ERROR_MESSAGE_NIP_PORTABILITY(): string {
    return 'Ha ocurrido un error al enviar el Código NIP, por favor intenta nuevamente.';
  }

  /**
   * Error Generico Portability request
   */
  public static get ERROR_MESSAGE_PORTABILITY(): string {
    return 'Ha ocurrido un error al solicitar la portabilidad, por favor intenta nuevamente.';
  }

  /**
   * Mensaje de error al verificar la redemicion de un codigo
   */
  public static get ERROR_MESSAGE_VERIFY_REDEMPTION_DISCOUNT(): string {
    return 'La linea no cumple las condiciones para redimir el descuento de este producto';
  }

  /**
   * URL de callback para pagos por pse o mercado pago
   */
  public static get URL_CALLBACK_VIRGIN(): string {
    // return 'http://localhost:4200/carrito-paso3/estado/';
    return environment.UrlOrigen + '/carrito-de-compra/paso-3/estado/';
  }

  /**
   * URL de callback para pagos por pse o mercado pago
   */
  public static get URL_CALLBACK_VIRGIN_LEADS(): string {
    // return 'http://localhost:4200/carrito-paso3/estado/';
    return environment.UrlOrigen + '/pasate/portabilidad/resumen/';
  }

  /**
   * Regex para validacion de email
   */
  public static get REGEX_ONLY_NUMBERS(): string {
    return '[ ]*[0-9]+[ ]*';
  }

  /**
   * Regex para validacion de email
   */
  public static get REGEX_EMAIL(): string {
    return '[a-zA-Z0-9!#$%&\'*+=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])';
  }

  /**
   * Identificador del estado activo en BD
   */
  public static get ACTIVE_STATE_ID(): number {
    return 1;
  }

  /**
   * Href para el boton de whatsapp linea SAC
   */
  public static get WHATSAPP_HREF(): string {
    return 'https://api.whatsapp.com/send?phone=573192510001';
  }

  /**
   * Href para el boton de whatsapp linea ventas
   */
  public static get WHATSAPP_HREF_SIM(): string {
    return 'https://api.whatsapp.com/send?phone=573195550005&text=%C2%A1Hola!%20Quiero%20traer%20mi%20n%C3%BAmero%20a%20Virgin%20Mobile%20%F0%9F%91%8A';
  }

  /**
   * Token generico
   */
  public static get TOKEN_GENERICO(): string {
    return 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJpYW5icEBnbWFpbC5jb20iLCJzY29wZXMiOiJST0xFX1VTRVIiLCJuYW1lcyI6IkJFQ0VSUkEiLCJsYXN0bmFtZXMiOiJQRVJFWiBGQUJJQU4gRURVQVJETyIsImlhdCI6MTU4NTQzNTQ5MiwiZXhwIjoxOTAwOTY4MjkyfQ.OzQG-4jej0mjO-5WTbrXytyo6fnyXpJpzVlY-zK1Gj0';
  }

  /**
   * Direccion Generica VIRGIN
   */
  public static get DIRECCION_VIRGIN(): string {
    return 'Cll 93 b # 13 95';
  }

  /**
   * Lista de tipos de identificacion
   */
  public static get IDENTIFICATION_TYPE_LIST(): SelectItem[] {
    return [
      { label: 'CC', value: 'CC' },
      { label: 'NIT', value: 'NIT' },
      { label: 'CE', value: 'CE' },
      { label: 'PPT', value: 'PPT' },
      { label: 'OTRO', value: 'CE' },

    ];
  }

  /**
   * Lista de tipos de facturacion
   */
  public static get IDENTIFICATION_TYPE_BILLING_LIST(): SelectItem[] {
    return [
      { label: 'CC', value: 'CC' },
      { label: 'NIT', value: 'NIT' },
      { label: 'CE', value: 'CE' },
      { label: 'PASSPORT', value: 'PASSPORT' },
      { label: 'PPT', value: 'PPT' }
    ];
  }

  /**
   * Lista de tipos de identificacion para activaciones
   */
  public static get IDENTIFICATION_TYPE_LIST_ACT(): SelectItem[] {
    return [
      { label: 'CC', value: 1 },
      { label: 'NIT', value: 3 },
      { label: 'CE', value: 2 },
      { label: 'PPT', value: 5 },
    ];
  }

  /**
   * Lista de tipos de identificacion usados en INEW
   */
  public static get IDENTIFICATION_TYPE_LIST_INEW(): SelectItem[] {
    return [
      { label: 'CC', value: 'ID' },
      { label: 'NIT', value: 'NIT' },
      { label: 'CE', value: 'FOREIGN_ID' },
      { label: 'PPT', value: 'OTHER' }
    ];
  }

  /**
   * Lista de tipos de persona
   */
  public static get PERSON_TYPE_LIST(): SelectItem[] {
    return [
      { label: 'Natural', value: 'N' },
      { label: 'Jurídica', value: 'J' },
    ];
  }

  /**
   * Lista de tipos de recursos a transferir
   */
  public static get RESOURCE_TYPE_LIST(): SelectItem[] {
    return [{ label: 'Megas', value: 'MB' }];
  }

  /**
   * Lista de tipos de PQR
   */
  public static get REQUEST_TYPE_LIST(): SelectItem[] {
    return [
      { label: 'PETICIÓN', value: '184' },
      { label: 'QUEJA', value: '185' },
      { label: 'RECURSO', value: '186' },
      { label: 'RECURSO CON SUBSIDIO DE APELACIÓN', value: '187' },
    ];
  }

  /**
   * Palabras clave PQR a ocultar
   */
  public static get KEY_WORDS_NOT_VISIBLE(): string[] {
    return [
      'NOT_VISIBLE',
      'TOKEN NO ENCONTRADO',
      'ERROR PAGO EN AGENDAMIENTO',
      'ERROR AL ACTIVAR PRODUCTO',
      'FALLO LA CREACI&#XFFFD;N DE LA TRANSACCI&#XFFFD;N',
    ];
  }

  /**
   * Lista de tipos de PQR que se van a permitir por Web
   */
  public static get REQUEST_TYPE_LIST_VIEW(): SelectItem[] {
    return [
      { label: 'PETICIÓN', value: '184' },
      { label: 'QUEJA', value: '185' },
      //, { label: 'RECURSO', value: '186' },
      //, { label: 'RECURSO CON SUBSIDIO DE APELACIÃ“N', value: '187' }
    ];
  }

  /**
   * Lista de estados de tickets
   */
  public static get LIST_STATUS_TICKETS(): SelectItem[] {
    return [
      { value: '1', label: 'Abierto' },
      { value: '3', label: 'Resuelto' }, // - Cerrado' }
      //, { value: '13', label: 'Escalado a Regulador' }
      //, { value: '14', label: 'Silencio Administrativo Positivo' }
      //, { value: '15', label: 'Trasladado a operador' }
      //, { value: '16', label: 'Acumulado con CUN' }
      //, { value: '17', label: 'Anulado' }
      { value: '18', label: 'Abierto' }, // con contacto pendiente' }
      //, { value: '19', label: 'En analisis Virgin con contacto pendiente' }
      //, { value: '20', label: 'En analisis Virgin' }
      { value: '21', label: 'Resuelto' }, // - recurso no posible' }
      { value: '22', label: 'Resuelto' }, // - recurso posible' }
      //, { value: '23', label: 'Etapa de pruebas Virgin' }
      //, { value: '24', label: 'Recurso de reposicion en analisis Virgin' }
      //, { value: '25', label: 'Recurso de reposicion en etapa de pruebas Virgin' }
      //, { value: '26', label: 'NO Interesa Continuar' }
      //, { value: '27', label: 'En proceso de traslado a SIC' }
    ];
  }

  /**
   * Lista de correos para envio de notificaciones
   * */
  public static get LIST_EMAILS_NOTIFICATION(): string[] {
    return [
      'backstage@virginmobilecolombia.com',
      //,''
    ];
  }

  /**
   * Lista de tipos de PQR
   */
  public static get HISTORICAL_TYPE_LIST(): SelectItem[] {
    return [
      { label: 'Histórico Voz', value: 'VOICE' },
      { label: 'Histórico Datos', value: 'DATA' },
      { label: 'Histórico Recargas y Compras', value: 'TOPUP' },
    ];
  }

  /**
   * Lista de productos de Add On de Tik Tok
   *
   * @Label atributo que corresponde a la cantidad de Días
   * @Value atributo que corresponde al valor del paquete
   * @gb atributo que corresponde al numero de Gigabytes
   */
  public static get ADD_ON_TIKTOK_LIST(): any[] {
    const productType = 'BUNDLE';
    return [
      {
        productName: 'BOLSA DE TIKTOK ILIMITADO',
        productType: productType,
        productCode: "c039c730-dc9c-4713-87b9-96e10d020afd",
        label: '30',
        productValue: 30000,
        gb: 'ILIMITADO',
        src: 'https://cdn1.virginmobile.co/wp-content/uploads/wpfiles/20220506075834/tik-tok-logo-h.svg',
        src2: 'https://www-angular.s3.amazonaws.com/img/oferta/apps-oferta/tiktok/tiktok-secondary.svg',
        catalogProductId: 'c039c730-dc9c-4713-87b9-96e10d020afd',
      },
      {
        productName: 'BOLSA DE TIKTOK DE $15.000',
        productType: productType,
        productCode: '20ebb04d-ca4b-44bc-8ecc-9e9b49e6550a',
        label: '30',
        productValue: 15000,
        gb: '3GB',
        src: 'https://cdn1.virginmobile.co/wp-content/uploads/wpfiles/20220506075834/tik-tok-logo-h.svg',
        src2: 'https://www-angular.s3.amazonaws.com/img/oferta/apps-oferta/tiktok/tiktok-secondary.svg',
        catalogProductId: '20ebb04d-ca4b-44bc-8ecc-9e9b49e6550a',
      },
      {
        productName: 'BOLSA DE TIKTOK DE $10.000',
        productType: productType,
        productCode: '1c99da73-8c12-49bd-ac38-1e153dc6ac49',
        label: '15',
        productValue: 10000,
        gb: '1,5GB',
        src: 'https://cdn1.virginmobile.co/wp-content/uploads/wpfiles/20220506075834/tik-tok-logo-h.svg',
        src2: 'https://www-angular.s3.amazonaws.com/img/oferta/apps-oferta/tiktok/tiktok-secondary.svg',
        catalogProductId: '1c99da73-8c12-49bd-ac38-1e153dc6ac49',
      },
    ];
  }

  /**
   * Lista de productos (/inicio/compra) INTB1-5
   * Se dará de baja catalogo de productos, por eso se crea esta constante
   */
  public static get PRODUCTS_LIST(): any[] {
    return [
      {
        "id": 1,
        "code": "TARIFF",
        "name": "Planes",
        "description": "Son PLANES pero bien, traen todo lo que necesitas. Mira y veras.",
        "stateCategory": null,
        "imageUrl": "https://apps-src.s3.amazonaws.com/android/img/icons/icon-antiplanes.png",
        "defOrder": 1,
        "subcategoryList": [],
        "subCategorySize": null,
        "categoryUrl": "antiplanes"
      },
      {
        "id": 2,
        "code": "BUNDLE",
        "name": "Bolsas",
        "description": "Engalla tu Sim con una bolsa de voz, bolsa de datos o bolsas de Chat de WhatsApp. Echales una miradita!",
        "stateCategory": null,
        "imageUrl": "https://apps-src.s3.amazonaws.com/android/img/icons/icon-bolsas.png",
        "defOrder": 2,
        "subcategoryList": [],
        "subCategorySize": null,
        "categoryUrl": "bolsas"
      },
      {
        "id": 4,
        "code": "TOPUP",
        "name": "Recargas",
        "description": "Recarga BREVE, ultra facil y mega rapido, sin necesidad de registrarte.",
        "stateCategory": null,
        "imageUrl": "https://www-angular.s3.amazonaws.com/img/catalogo-de-productos/recargas.svg",
        "defOrder": 4,
        "subcategoryList": [],
        "subCategorySize": null,
        "categoryUrl": "recargas"
      },
      {
        "id": 3,
        "code": "SIM LÍNEA NUEVA",
        "name": "Sim Línea Nueva",
        "description": "Compra tu SIM de reposicion, comprala de una!",
        "stateCategory": null,
        "imageUrl": "https://www-angular.s3.amazonaws.com/img/catalogo-de-productos/sims.svg",
        "defOrder": 5,
        "subcategoryList": [],
        "subCategorySize": null,
        "categoryUrl": "compra-tu-sim"
      },
      {
        "id": 5,
        "code": "SIM REPO",
        "name": "Sim de Reposición",
        "description": "Pide tu sim de reposición",
        "stateCategory": null,
        "imageUrl": "https://www-angular.s3.amazonaws.com/img/catalogo-de-productos/sims.svg",
        "defOrder": 6,
        "subcategoryList": [],
        "subCategorySize": null,
        "categoryUrl": "sim-repo"
      }
    ]
  }

  public static get VALUE_MIN_CARD_DEBITO(): string {
    return '$1.000';
  }

  public static get VALUE_MIN_CARD_CREDITO(): string {
    return '$1.000';
  }

  public static get VALUE_FILTER_PLAN(): number {
    return 10000;
  }

  public static get LINKS_CONSUMO_ANDROID(): string {
    return 'https://www.youtube.com/watch?v=RH3Sq2iDbKE';
  }

  public static get LINKS_CONSUMO_IOS(): string {
    return 'https://www.youtube.com/watch?v=QkgUXpZy8ko';
  }

  /**
   * Mensaje de error de linea inactiva
  */
  public static get ERROR_MESSAGE_NO_ERES_PROPIETARIO(): string {
    return 'No eres propietario de esta línea';
  }

  public static get WOMPI_TYC(): string {
    return 'https://wompi.co/wcm/connect/wompi.co-30215/a4df8ca3-bba4-4d75-8d5f-c25cae46fecc/Terminos+y+condiciones+usuarios.pdf?MOD=AJPERES&CVID=o885G5R&';
  }

}

export enum ORIGEN {
  VMCO = 1,
  FACEBOOK = 2,
  GOOGLE = 4,
}

export enum PARENT_COMP_LOGIN {
  AUTENTICATION = 'autenticacion',
  STEP_ONE = 'step-one',
}

export enum PARENT_COMP_TOKENIZATION {
  STEP_TWO = 'carrito-de-compra/paso-2',
  USER_CREDIT_CARD = 'mi-cuenta/mi-tarjeta',
  LEAD_STEP_5 = 'pasate/portabilidad/checkout',
}

export enum TOKENIZATION_COMP_TYPE {
  COMPRA,
  TOKENIZATION,
  ACTUALIZACION,
  GUESS,
}

/**
 * @author Jonathan Arroyo Reina
 * @description constantes que contiene la ruta del path el cual se envia por DTO al back-end y esta enviarla via email
 * Tener en cuenta que si estos path originales cambian se deben ajustar aqui tambien
 */
export enum PATH_ORIGIN {
  SIGN_IN = '/activacion-cuenta',
  PASSWORD_RECOVERY = '/cambiar-contrasena',
}

export enum GOOGLE_ANALYTICS_EVENT_TYPE {
  GTMFormErrorLabel = 'GTMFormErrorLabel',
  GTMFormErrorModal = 'GTMFormErrorModal'
}

export enum PRODUCT_TYPE {
  TOPUP = 'TOPUP',
}
