import { Component, OnInit, NgZone } from '@angular/core';
import { ProductCategory } from 'src/app/model/product-catalog/product-category';
import { DataService } from 'src/app/service/product-catalog/data.service';
import { ShoppingCartService } from 'src/app/service/product-catalog/shopping-cart.service';
import { UserService } from 'src/app/service/security-access/user.service';
import { Router } from '@angular/router';
import { EventsService } from 'angular4-events';
import { ANTIPLANES_CATEGORY_ID, BOLSAS_CATEGORY_ID, RECARGAS_CATEGORY_ID, SIMS_CATEGORY_ID } from 'src/app/utils/constants/endpoints/product-catalog/product-catalog.constant';

declare var $;
declare function hideNavsOnScroll(): any;

@Component({
  selector: 'app-no-logged',
  templateUrl: './no-logged.component.html',
  styleUrls: ['./no-logged.component.css']
})
export class NoLoggedComponent implements OnInit {
  /** Categorías de productos */
  categories: ProductCategory[];

  // --- true si se debe mostrar o no la notificacion
  currentShoppingCart = false;
  isAuthenticated = false;

  ANTIPLANES_CATEGORY_ID = ANTIPLANES_CATEGORY_ID;
  BOLSAS_CATEGORY_ID = BOLSAS_CATEGORY_ID;
  RECARGAS_CATEGORY_ID = RECARGAS_CATEGORY_ID;
  SIMS_CATEGORY_ID = SIMS_CATEGORY_ID;

  /**
   * Método contructor
   *
   */
  constructor(private dataService: DataService,
    private userService: UserService,
    private router: Router,
    private zone: NgZone,
    private events: EventsService,
    private shoppingCartService: ShoppingCartService) { }

  /**
   * Método que se ejecuta al inicio del componente
   */
  ngOnInit() {

    this.createEventLogin();
    this.shoppingCartService.currentShoppingCart.subscribe(currentShoppingCart => this.currentShoppingCart = currentShoppingCart);
    if (this.dataService.getSelectedCatalogItem() != null
      || this.dataService.getSelectedSimCatalogItem() != null
      || this.dataService.getRechargeValues() != null) {
      this.shoppingCartService.changeShoppingCart(true);
    }

    this.isAuthenticated = this.userService.isAuthenticated();

    hideNavsOnScroll();
    this.loadMouseOver();
  }

  private loadMouseOver(): void {
    // ------------user nav hover active subcategories on desktop and click on mobile -----------------
    if ($(window).width() > 768) {
      // ---load raleway font only on desktop---
      $('head').append('<link href="https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900&display=swap" rel="stylesheet">');

      $('.theme-header .user-nav ul .user-account, #ingresaMenu').mouseover(function () {
        $('#ingresaMenu').show();
      });
      $('.theme-header .user-nav ul .cart, .theme-header .user-nav ul .icon-bling').mouseover(function () {
        $('#ingresaMenu').hide();
      });
      $('.user-nav').on('mouseleave', function () {
        $('#ingresaMenu').hide();
      });
    } else {
      $('.theme-header .user-nav ul .user-account').on('click', function () {
        $('#ingresaMenu').slideToggle();
      });
      $('#ingresaMenu').on('click', function () {
        $(this).slideUp();
      });
    }
  }

  createEventLogin() {
    console.log('updateMiCuenta', 'create');
    this.events.subscribe('updateMiCuenta', () => {
      this.zone.run(() => {

        console.log('updateMiCuenta', 'an llamado');
        this.isAuthenticated = this.userService.isAuthenticated();
      });
    });
  }

  /**
   * Método encargado de enviar el ID de la categoría seleccionada hacía el componente
   * de renderización
   *
   * @author SW CONSULTING
   *        Ricardo Ayala Martínez <br>
   *        Email: rayala@swat-it.co <br>
   *
   */
  selectCategory(category: number) {
    console.log('update category selected...');
    this.dataService.updateSelectedCategory(category);
  }

  selectRecarga() {
  }

  cerrarSesion() {
    this.userService.logout();
    this.router.navigate(['/ingreso']);
    this.isAuthenticated = false;
  }

}
