/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-content.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./top-content.component";
var styles_TopContentComponent = [i0.styles];
var RenderType_TopContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopContentComponent, data: {} });
export { RenderType_TopContentComponent as RenderType_TopContentComponent };
export function View_TopContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-fluid su-top-content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "logo-app"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "logo app"], ["src", "../../../../../assets/themepuro/img/logo-app.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "text-light text-museo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lorem ipsum dolor sit amet, consectetuer adipiscing elit"]))], null, null); }
export function View_TopContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-content", [], null, null, null, View_TopContentComponent_0, RenderType_TopContentComponent)), i1.ɵdid(1, 114688, null, 0, i2.TopContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopContentComponentNgFactory = i1.ɵccf("app-top-content", i2.TopContentComponent, View_TopContentComponent_Host_0, {}, {}, []);
export { TopContentComponentNgFactory as TopContentComponentNgFactory };
