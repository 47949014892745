import { Component, OnInit } from '@angular/core';

declare function hideNavsOnScroll(): any;

@Component({
  selector: 'app-offer-nav',
  templateUrl: './offer-nav.component.html',
  styleUrls: ['./offer-nav.component.css']
})
export class OfferNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    hideNavsOnScroll();
  }

}
