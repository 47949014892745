import { environment } from 'src/environments/environment';

export class BackendConstants {

  private static readonly _URL_ENDPOINT_BACKEND_PROD = 'https://api.virginmobile.co/virgin30/api/v1';
  private static readonly _URL_ENDPOINT_BACKEND_DEVEL = 'https://zt5sso8yb1.execute-api.us-east-1.amazonaws.com/dev/api/v1'; // MBTEST

  private static readonly _URL_ENDPOINT_BACKEND_SECURE_PROD = 'https://api.virginmobile.co/virgin30sec/api/v1';
  private static readonly _URL_ENDPOINT_BACKEND_SECURE_DEVEL = 'https://zwcaxszj6e.execute-api.us-east-1.amazonaws.com/dev/api/v1'; // MBTEST

  public static get URL_ENDPOINT_BACKEND(): string {
    return environment.production
      ? BackendConstants._URL_ENDPOINT_BACKEND_PROD
      : BackendConstants._URL_ENDPOINT_BACKEND_DEVEL;
  }

  public static get URL_ENDPOINT_BACKEND_SECURE(): string {
    return environment.production
      ? BackendConstants._URL_ENDPOINT_BACKEND_SECURE_PROD
      : BackendConstants._URL_ENDPOINT_BACKEND_SECURE_DEVEL;
  }

}
