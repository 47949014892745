<div id="container-oferta-menu">
  <div class='menu-oferta-flotante'>
      <ul>
          <li><a routerLink="/antiplanes"><span class="gradient-icon icon-antiplanes"></span>PLANES</a></li>
          <li><a routerLink="/bolsas"><span class="gradient-icon icon-bolsas"></span>BOLSAS</a></li>
          <li><a routerLink="/recargas"><span class="gradient-icon icon-recargas"></span>RECARGAS</a></li>
          <li><a routerLink="/sim-linea-nueva"><span class="gradient-icon icon-compra-sim"></span>COMPRA TU SIM</a></li>
          <li><a href='https://www.virginmobile.co/inicio/pasate/portabilidad/'><span class="gradient-icon icon-porta"></span>PORTABILIDAD</a></li>
      </ul>
  </div>
</div>