<div class="sign-up-wrapper">
  <div class="container">
    <div class="row">
      <section class="col-md-12">
        <app-top-content></app-top-content>
      </section>
      <section class="col-md-5 wrapper-container left-content">
        <app-img-reference class="container-content middle-position"></app-img-reference>
      </section>
      <section class="col-md-7 wrapper-container right-content">
        <app-register class="container-content middle-position">
        </app-register>
      </section>
    </div>
  </div>
</div>