import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class ShoppingCartService {
    constructor() {
        this.messageSource = new BehaviorSubject(null);
        this.currentShoppingCart = this.messageSource.asObservable();
    }
    /**
     * Actualiza el valor del carrito de compras
     * @param currentShoppingCart
     */
    changeShoppingCart(currentShoppingCart) {
        this.messageSource.next(currentShoppingCart);
    }
}
ShoppingCartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShoppingCartService_Factory() { return new ShoppingCartService(); }, token: ShoppingCartService, providedIn: "root" });
