import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class CanonicalLinkService {
    /**
     * Default constructor
     * @param dom Document para acceder a los
     *  atributos de la pagina
     */
    constructor(dom) {
        this.dom = dom;
    }
    /**
     * Crea o actualiza el link canonical en la pagina
     * con la url enviada o la url obtenida de la actual pagina
     * @param url
     */
    createOrUpdateCanonicalURL(url) {
        let canURL = url == undefined ? this.dom.URL : url;
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            canonical.setAttribute("href", this.getPathFromUrl(canURL));
        }
        else {
            let link = this.dom.createElement('link');
            link.setAttribute('rel', 'canonical');
            this.dom.head.appendChild(link);
            link.setAttribute('href', this.getPathFromUrl(canURL));
        }
    }
    /**
     * Elimina los query strings de la URL
     * @param url
     * @returns url sin query strings
     */
    getPathFromUrl(url) {
        return url.split(/[?#]/)[0];
    }
}
CanonicalLinkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanonicalLinkService_Factory() { return new CanonicalLinkService(i0.ɵɵinject(i1.DOCUMENT)); }, token: CanonicalLinkService, providedIn: "root" });
