import { Meta } from "@angular/platform-browser";
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class MetaService {
    constructor(meta) {
        this.meta = meta;
    }
    updateMetadata(desc, keywords) {
        if (desc) {
            this.meta.updateTag({ name: 'description', content: desc });
        }
        else {
            this.meta.removeTag("name='description'");
        }
        if (keywords) {
            this.meta.updateTag({ name: 'keywords', content: keywords });
        }
        else {
            this.meta.removeTag("name='keywords'");
        }
    }
}
MetaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MetaService_Factory() { return new MetaService(i0.ɵɵinject(i1.Meta)); }, token: MetaService, providedIn: "root" });
