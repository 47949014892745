import { NgModule } from '@angular/core';
import { FoPrincipalComponent } from './fo-principal/fo-principal.component';
import { FoNavComponent } from './fo-nav/fo-nav.component';
import { FoLogoComponent } from './fo-logo/fo-logo.component';
import { FoCreditsComponent } from './fo-credits/fo-credits.component';


@NgModule({
  imports: [
    
  ],
  declarations: [
    FoPrincipalComponent,
    FoNavComponent,
    FoLogoComponent,
    FoCreditsComponent
  ],
  exports: [
    FoPrincipalComponent,
    FoNavComponent,
    FoLogoComponent,
    FoCreditsComponent
  ]
})
export class FooterModule { }
